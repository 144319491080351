import React from 'react';
import http from './http';

// const basePath = "https://young-tor-08987.herokuapp.com/";
// export const imgPath = 'http://sms-api.fusionelleconsult.com/static/';

const basePath = "https://api.taussh.com/";
export const imgPath = "https://api.taussh.com/static/";

// const basePath = "https://test-api.taussh.com/";
// export const imgPath = "https://test-api.taussh.com/static/";

export const base = basePath;
export const baseUrl = base;
export const imageUrl = basePath + 'static/';
export const currency = '\u20A6';
export const jwtToken = "a5a3baefc771670d236f8011730621933ce22e50";
export const errorMsg = 'Sorry unable to process your request at this time';

export const urls = {
  users: 'users/',
}

export const messages = {
  defaultError: {
    code: 5000,
    message: 'Sorry we are not able to process this at this time, kindly retry',
  },
  loginError: {
    error: true,
    message: 'Sorry we are not able to process this at this time, kindly retry',
  },
};

export function formatDate(date) {
  if (date !== null) return date.substring(0, 25);
  else return '';
}

/**
 Get the baseUrl from the server
 */
export function getUserData() {
  let userData = localStorage.getItem('customer');
  let user = JSON.parse(userData);
  return user;
}

export function formatErrors(data) {
  var errors = [];
  if (typeof (data) !== 'object') {
    errors.push(<div>{data}</div>);
  } else {
    for (var e in data) {
      if (data[e][0] == "This field is required.")
        errors.push(<div>{e.replace(/^\w/, (c) => c.toUpperCase())} is a required field</div>);
      else
        errors.push(<div>{data[e][0]}</div>);
    }
  }
  return errors;
}

export function formatErrorsMsg(data) {
  var errors = [];
  for (var e in data) {
    if (data[e][0] == "This field is required.")
      errors.push(`${e.replace(/^\w/, (c) => c.toUpperCase())} is a required field`);
    else
      errors.push(data[e][0]);
  }
  return errors;
}

export function generateDropdownData(listData, columns, image = null) {
  var list = [];
  if (listData.code != undefined) {
    if (listData.code == 200) {
      var cols = columns.split("|");
      for (var d in listData.message.results) {
        const data = listData.message.results[d];
        var label = data[cols[1]];
        if (cols.length > 2) {
          label = "";
          for (var l in cols) {
            if (l > 0)
              label += data[cols[l]] + " - ";
          }
        }
        var content = { label: label, value: data[cols[0]] };
        if (image != null) {
          content.image = data[image];
        }
        list.push(content);
      }
    }
  }
  return list;
}

export function formateLabel(label) {
  if (label.endsWith("_id"))
    label = label.slice(0, -3);

  label = label.split("_").join(" ");
  label = label.replace(/^\w/, (c) => c.toUpperCase());
  //console.log(label);
  return label;
}

export function isLoggedIn() {
  let userData = localStorage.getItem('userD');
  if (userData !== null) {
    let user = JSON.parse(userData);
    if (user.id !== undefined) {
      return true;
    }
  }
  return false;
}

/**
 Check if the string is a json data
 */
export function isJSONValid(data) {
  try {
    let json = JSON.parse(data);
    return true;
  } catch (error) {
    return false;
  }
}

export async function GetToken() {
  try {
    let token = localStorage.getItem("jwtToken");
    let customer = localStorage.getItem("customer");

    if (customer == null) {
      if (token == null || token == "") {
        var formData = new URLSearchParams();
        formData.append("username", "website");
        formData.append("password", "admin");
        // console.log("getToken:::===>");
        var getToken = await RefToken("api/jwt/token/", formData);
        // console.log("getToken:::", getToken);
        if (getToken.code == 200) {
          localStorage.setItem("jwtToken", getToken.message.access);

          return getToken.message.access;
        }
      } else {
        return token;
      }
    } else {
      if (token == null || token == "") {
        var formData = new URLSearchParams();
        let tokenDetails = localStorage.getItem("tokenDetails");
        tokenDetails = JSON.parse(tokenDetails);

        formData.append("username", tokenDetails.username);
        formData.append("password", tokenDetails.password);

        var getToken = await RefToken("api/jwt/token/", formData);
        if (getToken.code == 200) {
          localStorage.setItem("jwtToken", getToken.message.access);
          return getToken.message.access;
        }
      } else {
        return token;
      }
    }
  } catch (err) {
    //console.log(err);
  }
}


/**
 Patch message
 @params url, data
 */
export async function PatchData(url, data, sid, formtype = null) {
  let user = await getUserData();
  try {

    let token = await GetToken();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    if (formtype == null)
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");


    var requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      body: data,
      redirect: 'follow'
    };

    return await fetch(base + url, requestOptions)
      .then(response => {
        return new Promise((resolve) => response.json()
          .then((json) => resolve({
            code: response.status,
            message: json,
          })));
      })
      .then(result => {
        //console.log(result);
        return result;
      })
      .catch(error => {
        return messages.defaultError;
      });
  } catch (error) {
    //console.log(error);
    return messages.defaultError;
  }
}

/**
Post message
@params url, data
*/
export async function PostData(url, data, sid, formtype = null) {
  let user = await getUserData();
  let token = await GetToken();
  try {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    if (formtype == null)
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'POST',
      body: data,
      headers: myHeaders,
      redirect: 'follow'
    };

    return await fetch(base + url, requestOptions)
      .then(response => {
        return new Promise((resolve) => response.json()
          .then((json) => resolve({
            code: response.status,
            message: json,
          })));
      })
      .then(result => {
        //console.log(result);
        return result;
      })
      .catch(error => {
        return messages.defaultError;
      });
  } catch (error) {
    //console.log(error);
    return messages.defaultError;
  }
}

/**
Post message
@params url, data
*/
export async function RefToken(url, data) {
  let user = await getUserData();
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'POST',
      body: data,
      headers: myHeaders,
      redirect: 'follow'
    };

    return await fetch(base + url, requestOptions)
      .then(response => {
        return new Promise((resolve) => response.json()
          .then((json) => resolve({
            code: response.status,
            message: json,
          })));
      })
      .then(result => {
        //console.log(result);
        return result;
      })
      .catch(error => {
        return messages.defaultError;
      });
  } catch (error) {
    //console.log(error);
    return messages.defaultError;
  }
}

/**
 Put Requests
 **/
export async function PutData(url, data, sid, formtype = null) {
  let user = await getUserData();
  let token = await GetToken();

  try {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    if (formtype == null)
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'PUT',
      body: data,
      headers: myHeaders,
      redirect: 'follow'
    };

    return await fetch(base + url, requestOptions)
      .then(response => {
        return new Promise((resolve) => response.json()
          .then((json) => resolve({
            code: response.status,
            message: json,
          })));
      })
      .then(result => {
        //console.log(result);
        return result;
      })
      .catch(error => {
        return messages.defaultError;
      });
  } catch (error) {
    //console.log(error);
    return messages.defaultError;
  }
}

/**
 * 
 * @param {*} url 
 * @returns 
 */
export async function GetData(url) {
  return await http.get(url).then(results => {
    return {
      code: results?.status,
      message: results?.data
    };
  }).catch(err => {
    return messages.defaultError;
  });

  // let user = await getUserData();
  // let token = await GetToken();
  // // console.log("token:::", token);
  // try {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  //   myHeaders.append("Authorization", "Bearer " + token);

  //   var requestOptions = {
  //     method: 'GET',
  //     headers: myHeaders,
  //     redirect: 'follow'
  //   };

  //   // console.log(base + url);
  //   return await fetch(base + url, requestOptions)
  //     .then(response => {
  //       return new Promise((resolve) => response.json()
  //         .then((json) => resolve({
  //           code: response.status,
  //           message: json,
  //         })));
  //     })
  //     .then(result => {
  //       // console.log(result);
  //       return result;
  //     })
  //     .catch(error => {
  //       return messages.defaultError;
  //     });
  // } catch (error) {
  //   //console.log(error);
  //   return messages.defaultError;
  // }
}

/**
 Delete
 **/
export async function DeleteData(url) {
  let user = await getUserData();
  try {
    var token = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    };

    //console.log(base + url);
    return await fetch(base + url, requestOptions)
      .then(response => response.status)
      .then(result => {
        //console.log(result);
        return result;
      })
      .catch(error => {
        return messages.defaultError;
      });
  } catch (error) {
    //console.log(error);
    return messages.defaultError;
  }
}
import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import Spinner from '../components/Spinner';

export default class Contact extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading:false,
            name:"",
            email:"",
            subject:"",
            message:"",
            loginerrormsg:""
        }
    }

    componentDidMount() {

    }

    sendEmail = async => {
        if (this.state.name == "") {
            this.setState({ loginerrormsg: "Full name is required" });
        } else if (this.state.email == "") {
            this.setState({ loginerrormsg: "Email address is required" });
        } else if (this.state.subject == "") {
            this.setState({ loginerrormsg: "Subject is required" });
        } else if (this.state.message == "") {
            this.setState({ loginerrormsg: "Message is required" });
        } else{
            var formData = new URLSearchParams();
            formData.append("name", this.state.name);
            formData.append("email", this.state.email);
            formData.append("subject", this.state.subject);
            formData.append("message", this.state.message);
            
        }
    }

    render() {
        return (
            <>
                <div className="header_sticky header-style-1 has-menu-extra">
                    <div className="boxed">
                        <Header />
                        <div className="page-title parallax parallax1">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="page-title-heading">
                                            <h1 className="title">Contacts</h1>
                                        </div>
                                        <div className="breadcrumbs">
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className="flat-row flat-iconbox">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="title-section">
                                            <h2 className="title">
                                                Get In Touch
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="iconbox text-center">
                                            <div className="box-header nomargin">
                                                <div className="icon">
                                                    <i className="fa fa-map-marker"></i>
                                                </div>
                                            </div>
                                            <div className="box-content">
                                                <p>Unit 17 Silens Works, <br/>29 Peckover Street, <br/>Bradford, BD1 5BD</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="divider h0"></div>
                                        <div className="iconbox text-center">
                                            <div className="box-header">
                                                <div className="icon">
                                                    <i className="fa fa-phone"></i>
                                                </div>
                                            </div>
                                            <div className="box-content">
                                                <p>+44 1274 738 555 / +233 20 669 3347</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="divider h0"></div>
                                        <div className="iconbox text-center">
                                            <div className="box-header">
                                                <div className="icon">
                                                    <i className="fa fa-envelope"></i>
                                                </div>
                                            </div>
                                            <div className="box-content">
                                                <p>info@taussh.com</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="divider h43"></div>
                            </div>
                        </section>
                        <section className="flat-row flat-contact mail-chimp">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="title-section margin_bottom_17">
                                            <h2 className="title">
                                                Send Us An Email
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="wrap-contact">
                                    <strong style={{ color: "#A00",marginBottom:20 }}>{this.state.loginerrormsg}</strong>
                                        
                                        <form novalidate="" className="contact-form" id="contactform" method="post" action="">
                                            <div className="form-text-wrap clearfix">
                                                <div className="contact-name clearfix">
                                                    <label>Name</label>
                                                    <input type="text" aria-required="true" size="30" value="" name="author" id="author" onChange={(e)=>this.setState({name:e.target.value})}/>
                                                </div>
                                                <div className="contact-email">
                                                    <label>Email</label>
                                                    <input type="email" size="30" value="" name="email" id="email" onChange={(e)=>this.setState({email:e.target.value})}/>
                                                </div>
                                                <div className="contact-subject">
                                                    <label>Subject</label>
                                                    <input type="text" aria-required="true" size="30" value="" name="subject" id="subject" onChange={(e)=>this.setState({subject:e.target.value})}/>
                                                </div>
                                            </div>

                                            <div className="contact-message clearfix margin-top-40">
                                                <label>Message</label>
                                                <textarea className="" tabindex="4" name="message" required  onChange={(e)=>this.setState({message:e.target.value})}></textarea>
                                            </div>
                                            <div className="form-submit margin-top-32 ">
                                                <button className="contact-submit" onClick={()=>this.sendEmail()}>SEND</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <Footer />
                    </div>
                </div>
            </>
        );
    }
}
import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import { GetData } from '../common';
import $ from 'jquery';
import RevSlider, { Slide, Caption } from 'react-rev-slider';
import CookieConsent from "react-cookie-consent";
import Cookie from '../components/Cookie';

const config = {
    sliderType: "standard",
    sliderLayout: "auto",
    fullWidth: "on",
    dottedOverlay: "none",
    delay: 6000,
    navigation: {
        keyboardNavigation: "on",
        keyboard_direction: "horizontal",
        mouseScrollNavigation: "off",
        onHoverStop: "off",
        touch: {
            touchenabled: "on",
            swipe_threshold: 75,
            swipe_min_touches: 1,
            drag_block_vertical: false,
            swipe_direction: "horizontal"
        },
        arrows: {
            style: "custom",
            enable: false,
            hide_onmobile: true,
            hide_under: 768,
            hide_onleave: true,
            tmp: '',
            left: {
                h_align: "left",
                v_align: "center",
                h_offset: 0,
                v_offset: 0
            },
            right: {
                h_align: "right",
                v_align: "center",
                h_offset: 0,
                v_offset: 0
            }
        },
        bullets: {
            enable: true,
            hide_onmobile: true,
            style: "custom",
            hide_onleave: true,
            direction: "horizontal",
            h_align: "center",
            v_align: "bottom",
            h_offset: 0,
            v_offset: 30,
            space: 8,
            tmp: ''
        }
    },
    viewPort: {
        enable: true,
        outof: "pause",
        visible_area: "80%"
    },
    responsiveLevels: [2220, 1183, 975, 750],
    gridwidth: [1170, 970, 760, 320],
    gridheight: [860, 860, 560, 460],
    lazyType: "smart",
    parallax: {
        type: "scroll",
        origo: "enterpoint",
        speed: 400,
        levels: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
    },
    shadow: 0,
    spinner: "off",
    stopLoop: "off",
    stopAfterLoops: -1,
    stopAtSlide: -1,
    shuffle: "off",
    autoHeight: "off",
    fullScreenOffsetContainer: ".rev-slider-offset",
    hideThumbsOnMobile: "off",
    hideSliderAtLimit: 0,
    hideCaptionAtLimit: 0,
    hideAllCaptionAtLilmit: 0,
    debugMode: false,
    fallbacks: {
        simplifyAll: "off",
        nextSlideOnWindowFocus: "off",
        disableFocusListener: false,
    },
    customListings: {}
};


export default class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menus: [],
            products: [],
            morepdt: [],
            slides: [],
            trend: {},
            homelatestproduct: {},
            discountlabel: {},
            specials: {},
            currency: {},
            mobilesize: false
        }
    }

    componentDidMount() {
        this.setState({ mobilesize: (window.innerWidth <= 420) ? true : false })
        this.loadData();
        this.getProducts();
        this.getCustomListing();
    }

    loadData = async () => {
        var currency = localStorage.getItem("currency");
        if (currency !== null) {
            this.setState({ currency: JSON.parse(currency) });
        }
        var getmenu = await GetData("menu/");
        if (getmenu.message.code == "token_not_valid") {
            localStorage.removeItem("jwtToken");
            getmenu = await GetData("menu/");
        }

        if (getmenu.code == 200) {
            this.setState({ menus: getmenu.message.results });
        }

        var contentSlides = await GetData("content/?name=slides");
        if (contentSlides.code == 200) {
            this.setState({ slides: contentSlides.message.results });
        }

        /************************
         ****
        *************************/
        var trends = await GetData("content/?name=home-trends");
        if (trends.code == 200) {
            this.setState({ trend: trends.message.results[0] });
        }

        //
        var contentSlides = await GetData("content/?name=home-latest-product");
        if (contentSlides.code == 200) {
            if (contentSlides.message.results != undefined) {
                if (contentSlides.message.results[0] !== undefined) {
                    this.setState({ homelatestproduct: contentSlides.message.results[0] });
                }
            }
        }

        //
        var contentSlides = await GetData("content/?name=home-discount");
        if (contentSlides.code == 200) {
            if (contentSlides.message.results != undefined)
                if (contentSlides.message.results[0] !== undefined)
                    this.setState({ discountlabel: contentSlides.message.results[0] });
        }

        var contentSlides = await GetData("content/?name=home-special");
        // console.log("content/?name=home-special");
        if (contentSlides.code == 200) {
            if (contentSlides.message.results != undefined)
                if (contentSlides.message.results[0] !== undefined) {
                    var details = contentSlides.message.results[0];

                    var subcateory = contentSlides.message.results[0].content.replace(/<[^>]*>?/gm, '');
                    subcateory = subcateory.replace(/(<([^>]+)>)/ig, '');
                    var special_pdt_ids = subcateory.split(",");
                    details['products'] = [];
                    for (var s in special_pdt_ids) {
                        if (special_pdt_ids[s] !== "") {
                            var productList = await GetData(`products/${special_pdt_ids[s]}/?status=true`);
                            // console.log(productList.message);
                            if (productList.message != undefined) {
                                details['products'].push(productList.message);
                                this.setState({ specials: details });
                            }
                        }
                    }
                }
        }
    }

    getProducts = async () => {
        var getmenu = await GetData("products/?status=true");
        if (getmenu.message.code == "token_not_valid") {
            localStorage.removeItem("jwtToken");
            getmenu = await GetData("products/?status=true");
        }

        if (getmenu.code == 200) {
            this.setState({ products: getmenu.message.results });
        }

        var more = await GetData("discount/");
        if (more.code == 200) {
            this.setState({ morepdt: more.message.results });
        }
    }

    getCustomListing = async () => {
        var contentSlides = await GetData("content/?name=custom-listing");
        if (contentSlides?.code == 200) {
            if (contentSlides?.message?.results?.length) {
                let pidList = contentSlides?.message?.results?.map(c => {
                    let pid = c.content.replace(/<[^>]*>?/gm, '');
                    pid = pid.replace(/(<([^>]+)>)/ig, '');
                    return ({ ...c, pid: pid.split(",") });
                });
                Promise.all(
                    pidList.map(m => m.pid).flat(1)?.map(pdtid => {
                        return new Promise(async (resolve) => {
                            const productList = await GetData(`products/${pdtid}/?status=true`);
                            if (productList.message != undefined) {
                                resolve({ pid: pdtid, data: productList.message });
                            }
                        });
                    })
                ).then(results => {
                    pidList = pidList.map(m => ({ ...m, productList: results.filter(f => m.pid.includes(f.pid)).map(m => m.data) }))
                    this.setState({ customListings: pidList });
                }).catch(err => {

                })
            }
        }
    }


    render() {
        const { mobilesize, currency, specials, menus, products, morepdt, slides, trend, homelatestproduct, discountlabel } = this.state;
        return (
            <>
                <div className="header_sticky header-style-1 has-menu-extra">
                    <div className="boxed">
                        <Header />
                        {(slides.length > 0) &&
                            <RevSlider config={config}>

                                {slides.map((data, index) => {
                                    return <Slide
                                        key={data.title + index}
                                        src={data.image}
                                        alt="slidebg1"
                                        data-bgfit="cover"
                                        data-bgposition="left top"
                                        data-bgrepeat="no-repeat"
                                        slideProperties={{
                                            'data-transition': "fade",
                                            'data-slotamount': "7",
                                            'data-masterspeed': "1500"
                                        }}>

                                        <Caption
                                            class="tp-caption text-white skewfromrightshort fadeout"
                                            data-x="85"
                                            data-y="224"
                                            data-start="700"
                                            data-width="full"
                                            data-height="none"
                                            data-fontsize={mobilesize == true ? "18" : "24"}
                                            data-lineheight={mobilesize == true ? "28" : "72"}
                                            data-easing="Power4.easeInOut">
                                            {data.title.replace(/<[^>]*>?/gm, '')}
                                        </Caption>

                                        <Caption
                                            class="tp-caption tp-resizeme text-white skewfromrightshort font-weight-500"
                                            data-x="85"
                                            data-y="280"
                                            data-start="900"
                                            data-width="full"
                                            data-height="none"
                                            data-fontsize={mobilesize == true ? "28" : "52"}
                                            data-lineheight={mobilesize == true ? "32" : "60"}
                                            data-easing="Power4.easeInOut">
                                            {data.content.replace(/<[^>]*>?/gm, '')}
                                        </Caption>
                                        {data.url != "" &&
                                            <Caption
                                                class="tp-caption"
                                                data-x="85"
                                                data-y="350"
                                                data-start="1000"
                                                data-width="full"
                                                data-height="none"
                                                data-easing="Power4.easeInOut">
                                                <a href={data.url} class="themesflat-button has-padding-36 bg-accent has-shadow"><span>DETAILS</span></a>
                                            </Caption>}
                                    </Slide>
                                })}
                            </RevSlider>
                        }

                        <section className="flat-row row-image-box">
                            <div className="container">
                                <div className="row gutter-10">
                                    {(menus[0] !== undefined) &&
                                        <div className="col-sm-6 col-md-4" style={{ maxHeight: 500, overflow: 'hidden' }}>
                                            <div className="flat-image-box style-1 data-effect div-h22 clearfix">
                                                <div className="item data-effect-item">
                                                    <div className="inner">
                                                        <div className="thumb">
                                                            <img src={menus[0]["image"]} alt="Image" />
                                                            <div className="elm-btn" style={{ top: 240 }}>
                                                                <a href={"/catalog?menu=" + menus[0]["id"]} className="themesflat-button bg-white width-150">{menus[0]["name"]}</a>
                                                            </div>
                                                            <div className="overlay-effect bg-color-1"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                    <div className="col-sm-6 col-md-4">
                                        <div className="flat-image-box style-1 row2 data-effect clearfix">
                                            {(menus[1] !== undefined) &&
                                                <div className="item data-effect-item" style={{ maxHeight: 240, overflow: 'hidden' }}>
                                                    <div className="inner">
                                                        <div className="thumb">
                                                            <img src={menus[1]["image"]} alt="Image" />
                                                            <div className="elm-btn">
                                                                <a href={"/catalog?menu=" + menus[1]["id"]} className="themesflat-button bg-white width-150">{menus[1]["name"]}</a>
                                                            </div>
                                                            <div className="overlay-effect bg-color-1"></div>
                                                        </div>
                                                    </div>
                                                </div>}

                                            {(menus[2] !== undefined) &&
                                                <div className="item data-effect-item" style={{ maxHeight: 240, overflow: 'hidden' }}>
                                                    <div className="inner">
                                                        <div className="thumb">
                                                            <img src={menus[2]["image"]} alt="Image" />
                                                            <div className="elm-btn">
                                                                <a href={"/catalog?menu=" + menus[2]["id"]} className="themesflat-button bg-white width-150">{menus[2]["name"]}</a>
                                                            </div>
                                                            <div className="overlay-effect bg-color-1"></div>
                                                        </div>
                                                    </div>
                                                </div>}
                                        </div>
                                    </div>
                                    {(menus[3] !== undefined) &&
                                        <div className="col-sm-6 col-md-4" style={{ maxHeight: 500, overflow: 'hidden' }}>
                                            <div className="flat-image-box style-1 data-effect div-h20 clearfix">
                                                <div className="item data-effect-item">
                                                    <div className="inner">
                                                        <div className="thumb">
                                                            <img src={menus[3]["image"]} alt="Image" />
                                                            <div className="elm-btn" style={{ top: 240 }}>
                                                                <a href={"/catalog?menu=" + menus[3]["id"]} className="themesflat-button bg-white width-150">{menus[3]["name"]}</a>
                                                            </div>
                                                            <div className="overlay-effect bg-color-1"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                </div>
                            </div>
                        </section>


                        <section className="flat-row row-product-new">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="title-section margin-bottom-52">
                                            <h2 className="title">
                                                {homelatestproduct.title}
                                            </h2>
                                        </div>

                                        <div className="product-content product-fourcolumn clearfix">
                                            <ul className="product style2 clearfix">
                                                {products.map((data, index) => {
                                                    var img = "";
                                                    if (data.images.length > 0)
                                                        img = data.images[0].image;

                                                    var priceConverted = Number(data.price) * Number(currency.rate);
                                                    priceConverted = priceConverted.toFixed(2);

                                                    if (index < 4) {
                                                        return <li className="product-item" key={index + data.name}>
                                                            <div className="product-thumb clearfix"
                                                                style={{ height: 370, overflow: 'hidden', marginBottom: 10, background: '#fff' }}>
                                                                <a href={"/details?id=" + data.id} className="product-thumb">
                                                                    <img src={img} alt="image" height="80" />
                                                                </a>
                                                                <span className="new">New</span>
                                                            </div>
                                                            <div className="product-info text-center clearfix">
                                                                <p className="product-title" style={{ height: 50 }}>{data.name}</p>
                                                                <div className="price">
                                                                    <ins>
                                                                        <span className="amount"> {currency.name} {priceConverted}</span>
                                                                    </ins>
                                                                </div>
                                                            </div>
                                                            <div className="add-to-cart text-center">
                                                                <a href={"/details?id=" + data.id}>DETAILS</a>
                                                            </div>
                                                        </li>;
                                                    }
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {(trend.id !== undefined) &&
                            <section className="flat-row row-animation-box bg-section row-1">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="flat-animation-block" >
                                                <div className="title-section width-before-17 bg-before-white margin-bottom-14">
                                                    <div className="sup-title"><span>{trend.title}</span></div>
                                                    <h2 className="title font-size-52 line-height-76">{trend.content.replace(/<[^>]*>?/gm, '').split("&nbsp;").join("")}</h2>
                                                </div>
                                                <div className="elm-btn text-center">
                                                    <a href={trend.url} className="themesflat-button bg-accent has-padding-36">Shop Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>}

                        {(specials.products != undefined) &&
                            <section className="flat-row row-product-project style-1">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="title-section margin-bottom-41">
                                                <h2 className="title">{specials.title}</h2>
                                            </div>
                                            <div className="divider h54"></div>
                                            <div className="product-content product-fourcolumn clearfix">
                                                <ul className="product style2 isotope-product clearfix">
                                                    {specials.products.map((data, index) => {
                                                        var img = "";
                                                        var discount = 0;
                                                        if (data.discount > 0) {
                                                            discount = (data.price - ((data.discount / 100) * data.price));
                                                            discount = discount.toFixed(2);
                                                        }

                                                        if (data.images?.length)
                                                            img = data.images[0].image;


                                                        var price_ = (data.discount > 0) ? discount : data.price;
                                                        var priceConverted = Number(price_) * Number(currency.rate);
                                                        var Normalprice = Number(data.price) * Number(currency.rate);
                                                        Normalprice = Normalprice.toFixed(2);
                                                        priceConverted = priceConverted.toFixed(2);

                                                        if (index < 4) {
                                                            return <li className="product-item kid woman" key={index}>
                                                                <div className="product-thumb clearfix"
                                                                    style={{ height: 370, overflow: 'hidden', marginBottom: 10, background: '#fff' }}>
                                                                    <a href={"/details?id=" + data.id}>
                                                                        <img src={img} alt="image" height="80" />
                                                                    </a>
                                                                    {(data.discount > 0) && <span className="new sale" style={{ width: 'auto', padding: '0px 10px' }}>{data.discount}% Sale</span>}
                                                                </div>
                                                                <div className="product-info clearfix">
                                                                    <p className="product-title" style={{ height: 50 }}>{data.name}</p>
                                                                    <div className="price">
                                                                        {(data.discount > 0) && <del style={{ color: '#444', marginRight: 5 }}><span className="regular" style={{ color: '#444', fontWeight: 'bold', fontSize: 10 }}> {currency.name} {Normalprice}</span></del>}
                                                                        <ins>
                                                                            <span className="amount"> {currency.name} {price_}</span>
                                                                        </ins>
                                                                    </div>
                                                                </div>
                                                                <div className="add-to-cart text-center">
                                                                    <a href={"/details?id=" + data.id}>DETAILS</a>
                                                                </div>
                                                            </li>;
                                                        }
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        }

                        {
                            this.state.customListings?.length && <>
                                {this.state.customListings?.filter(f => f.productList.length).map(m =>
                                    <section key={`section_${m.id}`} className="flat-row row-product-project style-1">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="title-section margin-bottom-41">
                                                        <h2 className="title">{m.title}</h2>
                                                    </div>

                                                    <div className="divider h54"></div>
                                                    <div className="product-content product-fourcolumn clearfix">
                                                        <ul className="product style2 isotope-product clearfix">
                                                            {m?.productList?.map((data, index) => {
                                                                var img = "";
                                                                var discount = 0;
                                                                if (data.discount > 0) {
                                                                    discount = (data.price - ((data.discount / 100) * data.price));
                                                                    discount = discount.toFixed(2);
                                                                }

                                                                if (data?.images?.length)
                                                                    img = data.images[0].image;

                                                                var price_ = (data.discount > 0) ? discount : data.price;
                                                                var priceConverted = Number(price_) * Number(currency.rate);
                                                                var Normalprice = Number(data.price) * Number(currency.rate);
                                                                Normalprice = Normalprice.toFixed(2);
                                                                priceConverted = priceConverted.toFixed(2);

                                                                if (index < 4) {
                                                                    return <li key={`sub_section_${data.id}`} className="product-item kid woman" >
                                                                        <div className="product-thumb clearfix"
                                                                            style={{ height: 370, overflow: 'hidden', marginBottom: 10, background: '#fff' }}>
                                                                            <a href={"/details?id=" + data.id}>
                                                                                <img src={img} alt="image" height="80" />
                                                                            </a>
                                                                            {(data.discount > 0) && <span className="new sale" style={{ width: 'auto', padding: '0px 10px' }}>{data.discount}% Sale</span>}
                                                                        </div>
                                                                        <div className="product-info clearfix">
                                                                            <p className="product-title" style={{ height: 50 }}>{data.name}</p>
                                                                            <div className="price">
                                                                                {(data.discount > 0) && <del style={{ color: '#444', marginRight: 5 }}><span className="regular" style={{ color: '#444', fontWeight: 'bold', textDecorationLine: 'line-through' }}>{currency.name}  {Normalprice}</span></del>}
                                                                                <ins>
                                                                                    <span className="amount">{currency.name} {priceConverted}</span>
                                                                                </ins>
                                                                            </div>
                                                                        </div>
                                                                        <div className="add-to-cart text-center">
                                                                            <a href={"/details?id=" + data.id}>DETAILS</a>
                                                                        </div>
                                                                    </li>
                                                                }
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>)}
                            </>
                        }

                        <section className="flat-row mail-chimp">
                            <div className="container">
                                <div className="row">
                                    <div className='col-md-2'></div>
                                    <div className="col-md-8">
                                        <iframe width="100%" height="420"
                                            src="https://www.youtube.com/embed/ciumAJ2pCfM">
                                        </iframe>
                                    </div>
                                    <div className='col-md-2'></div>
                                </div>
                            </div>
                        </section>

                        <section className="flat-row mail-chimp">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="text">
                                            <h3>Sign up for Send Newsletter</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="subscribe clearfix">
                                            <form action="#" method="post" acceptCharset="utf-8" id="subscribe-form">
                                                <div className="subscribe-content">
                                                    <div className="input">
                                                        <input type="email" name="subscribe-email" placeholder="Your Email" />
                                                    </div>
                                                    <div className="button">
                                                        <button type="button">SUBCRIBE</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <Footer />
                        {/* <CookieConsent
                            location='bottom'
                            buttonText='I understand'
                            overlay='true'
                            enableDeclineButton
                            flipButtons
                            cookieName='taussh'
                            buttonStyle={{
                                background: "#ccc",
                                color: "lack",
                                fontWeight: "bolder",
                              }}
                            style={{ background: "#2B373B" }}
                            buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
                            expires={150}>
                            This website uses cookies to enhance the user experience.
                            Read <a href="/terms" style={{color:'#ccc',fontWeight:'bold'}}>Terms and Conditions</a> and <a href="/policy" style={{color:'#ccc',fontWeight:'bold'}}>Privacy Policy</a> for more information.
                        </CookieConsent> */}
                        <Cookie />
                    </div>
                </div>
            </>
        );
    }
}
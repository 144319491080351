import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import queryString from 'query-string';
import { currency, GetData } from '../common';

export default class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            product: {},
            id: "",
            related: [],
            illustrations: [],
            concepts: [],
            currency: { name: "USD" }
        }
    }

    componentDidMount() {
        this.getProducts();
        this.loadData();
    }

    loadData = async => {
        var currency = localStorage.getItem("currency");
        if (currency !== null) {
            this.setState({ currency: JSON.parse(currency) });
        }
    }

    getProducts = async () => {
        var id = queryString.parse(this.props.location.search).id;
        this.setState({ id: id });
        var getmenu = await GetData(`products/${id}/`);
        if (getmenu.message.code == "token_not_valid") {
            localStorage.removeItem("jwtToken");
            getmenu = await GetData(`products/${id}/`);
        }

        if (getmenu.code == 200) {
            this.setState({ product: getmenu.message });
        }

        var related = await GetData(`products/?status=true&category=${getmenu.message.category}`);
        if (related.code == 200) {
            var list = related.message.results.filter(function (data) {
                return data.id != id;
            });
            this.setState({ related: list });
        }

        setTimeout(function () {
            window.$('#carousel').flexslider({
                animation: "slide",
                controlNav: false,
                animationLoop: false,
                slideshow: false,
                itemWidth: 180,
                itemMargin: 5,
                asNavFor: '#slider'
            });

            window.$('#slider').flexslider({
                animation: "slide",
                controlNav: false,
                animationLoop: false,
                slideshow: false,
                sync: "#carousel"
            });
        }, 500);

        var illustrations = await GetData("illustrations/?status=true");
        var productillustrationsassignment = await GetData(`productillustrationsassignment/?product_id=${id}`);
        if (productillustrationsassignment.message.results.length > 0) {
            var values = [];
            var ids_ = [];
            for (var v in productillustrationsassignment.message.results) {
                ids_.push(productillustrationsassignment.message.results[v].illustrations_id);
            }

            var assigned = illustrations.message.results;
            assigned = assigned.filter(function (data) {
                return ids_.includes(data.id);
            });
            this.setState({ illustrations: assigned });
        }


        //Concepts

        var concepts = await GetData("concepts/?status=true");
        var productconceptassignment = await GetData(`productconceptassignment/?product=${id}`);
        if (productconceptassignment.message.results.length > 0) {
            var values = [];
            var ids_ = [];
            for (var v in productconceptassignment.message.results) {
                ids_.push(productconceptassignment.message.results[v].concept);
            }

            var assigned = concepts.message.results;
            assigned = assigned.filter(function (data) {
                return ids_.includes(data.id);
            });
            this.setState({ concepts: assigned });
        }
    }

    render() {
        const { product, id, related, illustrations, concepts, currency } = this.state;

        var size = [];
        if (product.sizes != undefined) {
            var loopSize = product.sizes.split("|");
            if (product.sizes != "null") {
                for (var s in loopSize) {
                    size.push(<li style={{ borderLeft: '1px solid #ccc', paddingLeft: 10 }}><strong>{loopSize[s]}</strong></li>);
                }
            }
        }

        var discount = 0;
        if (product.discount > 0) {
            discount = (product.price - ((product.discount / 100) * product.price));
            discount = discount.toFixed(2);
        }

        var price_ = (product.discount > 0) ? discount : product.price;
        var priceConverted = Number(price_) * Number(currency.rate);
        priceConverted = priceConverted.toFixed(2);

        return (
            <>
                <div className="header_sticky header-style-1 has-menu-extra">
                    <div className="boxed">
                        <Header />
                        <div className="page-title parallax parallax1">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="page-title-heading">

                                            {(product.name != undefined) && <h1 className="title">{product.name}</h1>}
                                        </div>
                                        <div className="breadcrumbs">
                                            {(this.state.product.id != undefined) &&
                                                <ul>
                                                    <li><a href={`/catalog?menu=${this.state.product.menu}`}>{this.state.product.menu_name}</a></li>
                                                    <li><a href={`/catalog?category=${this.state.product.category}`}>{this.state.product.category_name}</a></li>
                                                    <li><a href={`/catalog?subcategory=${this.state.product.subcategory}`}>{this.state.product.subcategory_name}</a></li>
                                                </ul>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className="flat-row main-shop shop-detail">
                            <div className="container">
                                <div className="row">
                                    {(product.images != undefined && product.images.length > 0) &&
                                        <div className="col-md-6">
                                            <div id="slider" className="flexslider">
                                                <ul className="slides">
                                                    {(product.images.map((img) => {
                                                        return <li >
                                                            <img src={img.image} />
                                                        </li>
                                                    }))}
                                                </ul>
                                            </div>
                                            <div id="carousel" className="flexslider">
                                                <ul className="slides">
                                                    {(product.images.map((img) => {
                                                        return <li className="thumbs_slides" style={{ overflow: 'hidden', height: 250 }}>
                                                            <img src={img.image} style={{ height: '60px !important' }} />
                                                        </li>
                                                    }))}
                                                </ul>
                                            </div>
                                        </div>}

                                    <div className="col-md-6">
                                        <div className="product-detail">
                                            <div className="inner">
                                                <div className="content-detail">
                                                    {(product.name != undefined) && <h2 className="product-title">{product.name}</h2>}
                                                    <div className="flat-star style-1">

                                                    </div>
                                                    {(product.description != undefined) && <p>{product.description}</p>}
                                                    {(product.features != undefined) &&
                                                        <div className="product-color">
                                                            <span>Features :</span>
                                                            <ul className="">
                                                                {product.features.split("|").map((feature, index) => <li><strong>{index + 1}</strong> {feature}</li>)}
                                                            </ul>
                                                        </div>
                                                    }

                                                    {(product.discount > 0) &&
                                                        <div className="price">
                                                            <strong>Discount : {product.discount}%</strong>
                                                        </div>}
                                                    {(product.discount > 0) &&
                                                        <div className="price">
                                                            <strike className="amount"> {currency.name} {product.price}</strike>
                                                        </div>}
                                                    <div className="price">
                                                        {(product.price != undefined) && <ins><span className="amount">{currency.name} {priceConverted}</span></ins>}
                                                    </div>

                                                    {/* {(size.length > 0) &&
                                                        <div className="size">
                                                            <span>Size:</span>
                                                            <ul>
                                                                {size}
                                                            </ul>
                                                        </div>} */}
                                                    {(product.color_name != undefined) &&
                                                        <div className="product-color">
                                                            <span>Colors:</span>
                                                            <ul className="flat-color-list">
                                                                <li><a href="#" className={product.color_name}></a></li>
                                                            </ul>
                                                        </div>
                                                    }

                                                    <div className="product-quantity">
                                                        <div className="add-to-cart">
                                                            <a href={`/selection?id=${id}`}>SELECTIONS</a>
                                                        </div>
                                                    </div>
                                                    <div className="product-quantity">
                                                        {(illustrations.length > 0) &&
                                                            <div className="add-to-cart">
                                                                <a href="#" data-toggle="modal" data-target=".bd-example-modal-lg" style={{ background: '#444', border: '1px solid #999', color: '#fff' }}>ILLUSTRATIONS</a>
                                                            </div>}
                                                        {(concepts.length > 0) &&
                                                            <div className="add-to-cart">
                                                                <a href="#" data-toggle="modal" data-target=".bd-example-modal-lg1" style={{ background: '#444', border: '1px solid #999', color: '#fff' }}>CONCEPTS</a>
                                                            </div>}
                                                    </div>
                                                    <div className="product-categories">
                                                        <span>Categories: </span>{(product.category_name != undefined) && <a href="#">{product.category_name},</a>}
                                                    </div>
                                                    <div className="product-tags">
                                                        <span>SKU: </span>{(product.sku != undefined) && <a href="#">{product.sku}</a>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="flat-row shop-related">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="title-section margin-bottom-55">
                                            <h2 className="title">Related Products</h2>
                                        </div>

                                        <div className="product-content product-fourcolumn clearfix">
                                            <ul className="product style2">
                                                {related.map(data => {

                                                    var discount = 0;
                                                    if (data.discount > 0) {
                                                        discount = (data.price - ((data.discount / 100) * data.price));
                                                        discount = discount.toFixed(2);
                                                    }

                                                    var price_ = (data.discount > 0) ? discount : data.price;
                                                    var priceConverted = Number(price_) * Number(currency.rate);
                                                    priceConverted = priceConverted.toFixed(2);

                                                    return (<li className="product-item" style={{ maxHeight: 470 }}>
                                                        <div className="product-thumb clearfix" style={{ height: 370, overflow: 'hidden', marginBottom: 10, background: '#fff' }}>
                                                            <a href={"/details?id=" + data.id}>
                                                                <img src={data.images[0].image} alt="image" />
                                                            </a>
                                                        </div>
                                                        <div className="product-info clearfix">
                                                            <p className="product-title" style={{ minHeight: 60 }}>{data.name}</p>
                                                            <div className="price">
                                                                <ins>
                                                                    <span className="amount">{currency.name} {priceConverted}</span>
                                                                </ins>
                                                            </div>
                                                        </div>
                                                        <div className="add-to-cart text-center">
                                                            <a href={"/details?id=" + data.id}>DETAILS</a>
                                                        </div>
                                                    </li>);
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="flat-row mail-chimp">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="text">
                                            <h3>Sign up for Send Newsletter</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="subscribe clearfix">
                                            <form action="#" method="post" acceptCharset="utf-8" id="subscribe-form">
                                                <div className="subscribe-content">
                                                    <div className="input">
                                                        <input type="email" name="subscribe-email" placeholder="Your Email" />
                                                    </div>
                                                    <div className="button">
                                                        <button type="button">SUBCRIBE</button>
                                                    </div>
                                                </div>
                                            </form>
                                            <ul className="flat-social">
                                                <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                                <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                                <li><a href="#"><i className="fa fa-google"></i></a></li>
                                                <li><a href="#"><i className="fa fa-behance"></i></a></li>
                                                <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <div className="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content" style={{ padding: 40 }}>
                                    <div className="modal-header modal-mobile">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <h3>Product Illustrations</h3><hr />
                                    <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                                        <div className="carousel-inner">

                                            {illustrations.map((data, index) => {
                                                var active = (index == 0) ? "active" : "";
                                                return <div className={`carousel-item ${active}`}>
                                                    <div className="row">
                                                        <div className='col-md-6 col-sm-12 illustrationTxt'>
                                                            <img className="d-block w-100" src={data.image} alt="" />
                                                        </div>
                                                        <div className='col-md-6 col-sm-12'>
                                                            <div className='illustrationTxt'><h3>{data.name}</h3>
                                                                {data.content}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })}
                                        </div>

                                        <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev" style={{ right: 0, background: '#000', borderRadius: 40, width: 40, height: 40, top: '40%' }}>
                                            <span className="carousel-control-prev-icon" aria-hidden="true" style={{}}></span>
                                            <span className="sr-only">Previous</span>
                                        </a>

                                        <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next" style={{ right: 0, background: '#000', borderRadius: 40, width: 40, height: 40, top: '40%' }}>
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="sr-only">Next</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade bd-example-modal-lg1" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content" style={{ padding: 40 }}>
                                    <div className="modal-header modal-mobile">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <h3>Product Concepts</h3><hr />
                                    <div id="carouselExampleControls1" className="carousel slide" data-ride="carousel">
                                        <div className="carousel-inner">

                                            {concepts.map((data, index) => {
                                                var active = (index == 0) ? "active" : "";
                                                return <div className={`carousel-item ${active}`}>
                                                    <div className="row">
                                                        <div className='col-md-6 col-sm-12 illustrationTxt'>
                                                            <img className="d-block w-100" src={data.image} alt="First slide" />
                                                        </div>
                                                        <div className='col-md-6 col-sm-12'>
                                                        <div className='illustrationTxt'>
                                                            <h3>{data.name}</h3>
                                                            {data.content}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                        <a className="carousel-control-prev" href="#carouselExampleControls1" role="button" data-slide="prev" style={{ right: 0, background: '#000', borderRadius: 40, width: 40, height: 40, top: '40%' }}>
                                            <span className="carousel-control-prev-icon" aria-hidden="true" style={{}}></span>
                                            <span className="sr-only">Previous</span>
                                        </a>
                                        <a className="carousel-control-next" href="#carouselExampleControls1" role="button" data-slide="next" style={{ right: 0, background: '#000', borderRadius: 40, width: 40, height: 40, top: '40%' }}>
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="sr-only">Next</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Footer />
                    </div>
                </div>
            </>
        );
    }
}
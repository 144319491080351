import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import { currency, GetData, PostData, getUserData } from '../common';
import history from '../history';
import moment from 'moment';
import { usePaystackPayment } from 'react-paystack';
import { timers } from 'jquery';
import Spinner from '../components/Spinner';

const domain = window.location.hostname;
const authkey = process.env.REACT_APP_AUTH;
const base = process.env.REACT_APP_PAYMENT_BASE;
const paystack = process.env.REACT_APP_PAYSTACK_SECRET;
const successurl = process.env.REACT_APP_PAYMENT_SUCCESS_URL;

export default class Contact extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fname: "",
            lname: "",
            email: "",
            dob: "",
            mobile: "",
            gender: 1,
            state: "",
            country: "",
            city: "",
            state: "",
            zipcode: "",
            address: "",
            errormsg: "",
            loginerrormsg: "",
            loading: false,
            checkout: false,
            note: "",
            paymentmethods: [],
            total: "",
            payment: "",
            currency: { name: "" },
            submitting: false
        }
    }

    componentDidMount() {
        this.loadData();

        var user = localStorage.getItem("customer");
        if (user === null) {
            history.push("/login");
            window.location = window.location.href;
        }
    }

    loadData = async () => {

        var getUser = await getUserData();
        this.setState({
            email: getUser.email,
            fname: getUser.first_name,
            lname: getUser.last_name,
            address: getUser.address,
            city: getUser.city,
            state: getUser.state,
            zipcode: getUser.zip,
            country: getUser.country,
            dob: getUser.date_of_birth
        })

        var paymentMethods = await GetData("paymentmethods/");
        if (paymentMethods.code == 200) {
            this.setState({ paymentmethods: paymentMethods.message.results });
        }

        var currency = localStorage.getItem("currency");
        if (currency !== null) {
            currency = JSON.parse(currency);
            this.setState({ currency: currency });
        }

        var total = 0;
        var cart = localStorage.getItem("cart");
        if (cart !== null) {
            cart = JSON.parse(cart);
            for (var c in cart) {
                const cData = cart[c];

                var measurements = "";
                for (var m in cData.measurements) {
                    measurements += `${cData.measurements[m]['name']}:${cData.measurements[m]['mvalue']}|`;
                }
                var discount = cData.product.price;
                if (cData.product.discount !== undefined) {
                    if (cData.product.discount > 0) {
                        discount = (cData.product.price - ((cData.product.discount / 100) * cData.product.price));
                        discount = discount.toFixed(2);
                    }
                }

                var subttotal = Number(cData.quantity) * Number(discount);
                subttotal = Number(subttotal) * Number(currency.rate);
                total += subttotal;
                if (cData.delivery != undefined)
                    total += Number(cData.delivery) * Number(currency.rate);
            }
        }
        total = total.toFixed(2);
        this.setState({ total: total });
    }

    placeOrder = async () => {
        if (this.state.fname == "") {
            this.setState({ errormsg: "First name is required" });
        } else if (this.state.lname == "") {
            this.setState({ errormsg: "Last name is required" });
        } else if (this.state.email == "") {
            this.setState({ errormsg: "Email address is required" });
        } else if (this.state.mobile == "") {
            this.setState({ errormsg: "Mobile number is required" });
        } else if (this.state.dob == "") {
            this.setState({ errormsg: "Date of birth is required" });
        } else if (this.state.country == "") {
            this.setState({ errormsg: "Country is required" });
        } else if (this.state.address == "") {
            this.setState({ errormsg: "Address is required" });
        } else if (this.state.city == "") {
            this.setState({ errormsg: "City is required" });
        } else if (this.state.zipcode == "") {
            this.setState({ errormsg: "Zipcode is required" });
        } else if (this.state.username == "") {
            this.setState({ errormsg: "Username is required" });
        } else if (this.state.password == "") {
            this.setState({ errormsg: "Password is required" });
        } else if (this.state.password != this.state.confirmpassword) {
            this.setState({ errormsg: "Passwords do not match" });
        } else {
            //console.log("base::", base);
            this.setState({loading:true});
            if (this.state.payment == 3) {
                var user = localStorage.getItem("customer");
                user = JSON.parse(user);
                //console.log(user);
                var user_profile = await GetData(`users/${user.id}/`);
                user_profile = user_profile.message;
                //console.log(user_profile.email);
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${paystack}`);

                var amount_total = Number(this.state.total) * 100;
                var formdata = JSON.stringify({
                    "email": user_profile.email,
                    "amount": Number(amount_total).toFixed(2),
                    currency: this.state.currency.name.toUpperCase()
                })

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                var self = this;
                await fetch("https://api.paystack.co/transaction/initialize", requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.status == true) {
                            self.saveOrder(result);
                        }
                    })
                    .catch(error => {
                        //console.log('error', error);
                    });
            } else {

                var self = this;
                var response = await fetch(`${base}/v1/oauth2/token`, {
                    method: "post",
                    body: "grant_type=client_credentials",
                    headers: {
                        Authorization: `Basic ${authkey}`,
                    },
                }).then(response => response.json())
                    .then(result => {
                        self.paypalRaiseOrder(result);
                    })
                    .catch(error => {
                        //console.log('error', error)
                    });
            }
        }
    }

    paypalRaiseOrder = async (dataResult) => {
        var accessToken = dataResult.access_token;
        var url = `${base}/v2/checkout/orders`;
        var self = this;
        var cart = localStorage.getItem("cart");
        var items = [];
        var total_ = 0;
        var delivery_total = 0;
        if (cart !== null) {
            cart = JSON.parse(cart);
            for (var c in cart) {
                var price_ = cart[c].product.price;
                if (cart[c].product.discount != undefined) {
                    if (cart[c].product.discount > 0) {
                        price_ = (cart[c].product.price - ((cart[c].product.discount / 100) * cart[c].product.price));
                        price_ = price_.toFixed(2);
                    }
                }
                price_ = Number(price_) * Number(this.state.currency.rate);
                delivery_total += Number(cart[c].delivery) * Number(this.state.currency.rate);
                price_ += delivery_total;
                price_ = price_.toFixed(2);
                total_ += price_;

                items.push(
                    {
                        "name": cart[c].product.name,
                        "description": `Delivery Fee : ${this.state.currency.name.toUpperCase()} ${delivery_total}`,
                        "quantity": cart[c].quantity,
                        "unit_amount": {
                            "currency_code": this.state.currency.name.toUpperCase(),
                            "value": price_
                        }
                    });
            }
        }

        await fetch(url, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                intent: "CAPTURE",
                purchase_units: [
                    {
                        amount: {
                            currency_code: this.state.currency.name.toUpperCase(),
                            value: this.state.total,
                            breakdown: {
                                item_total: {
                                    currency_code: this.state.currency.name.toUpperCase(),
                                    value: this.state.total
                                }
                            }
                        },
                        items: items,
                    },
                ],
                application_context: {
                    "return_url": successurl,
                    "cancel_url": successurl
                    // "return_url": "http://localhost:3000/success",
                    // "cancel_url": "http://localhost:3000/success"
                }
            }),
        }).then(response => response.json())
            .then(result => {
                // console.log("Paypal:::", result);
                result.data = {
                    reference: result.id
                }
                self.saveOrder(result);
            })
            .catch(error => {
                // console.log('error', error)
            });
    }

    genToken = async () => {
        let self = this;
        await fetch(`${base}/v1/oauth2/token`, {
            method: "post",
            body: "grant_type=client_credentials",
            headers: {
                Authorization: `Basic ${authkey}`,
            },
        }).then(response => response.json())
            .then(result => {
                //console.log("Paypal:::", result);
                self.PaypalCapture(result.access_token);
            })
            .catch(error => console.log('error', error));;
    }

    PaypalCapture = async (accessToken) => {
        const url = `${base}/v2/checkout/orders/0MT346363V8840334/capture`;
        await fetch(url, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        }).then(response => response.json())
            .then(result => {
                //console.log("Paypal:::", result);
            })
            .catch(error => console.log('error', error));
    }

    saveOrder = async (payment) => {
        var user = localStorage.getItem("customer");
        user = JSON.parse(user);

        var payload = new URLSearchParams();
        payload.append("user_address2", `first_name: ${this.state.fname},
        last_name: ${this.state.lname},\n
        email: ${this.state.email},\n
        mobile: ${this.state.mobile},\n
        country: ${this.state.country},\n
        city: ${this.state.city},\n
        zipcode: ${this.state.zipcode},\n
        address: ${this.state.address},\n
        state: ${this.state.state}`);
        payload.append("user_id", user.id);
        payload.append("ordering_date", moment().format("YYYY-MM-DD"));
        payload.append("payment_method", this.state.payment);
        payload.append("comment", this.state.note);
        payload.append("order_status", 1);
        payload.append("shipping_method", 1);
        payload.append("payment_id", payment.data.reference);
        payload.append("payment_status", 0);
        payload.append("payment_status", 0);
        payload.append("currency", this.state.currency.id);
        payload.append("total_price", this.state.total);


        var order = await PostData("orders/", payload, false);

        if (order.code == 201) {
            this.saveInvoices(order.message, payment);
            this.setState({loading:false});
            if(this.state.payment==3)
                window.location=payment.data.authorization_url;
        } else {
            var errors = "";
            for (var e in user.message) {
                const data = user.message[e];
                for (var d in data) {
                    errors += `${data[d]}`;
                }
            }
            this.setState({ errormsg: errors });
        }
    }

    saveInvoices = async (order, payment) => {
        this.setState({ loading: true });
        var user = localStorage.getItem("customer");
        user = JSON.parse(user);
        //console.log("users::", user);

        var cart = localStorage.getItem("cart");
        if (cart !== null) {
            cart = JSON.parse(cart);
            for (var c in cart) {
                const cData = cart[c];
                var delivery_ = Number(cData.delivery) * Number(this.state.currency.rate);
                delivery_ = delivery_.toFixed(2);

                var price_ = cData.product.price;
                if (cData.product.discount != undefined) {
                    if (cData.product.discount > 0) {
                        price_ = (cData.product.price - ((cData.product.discount / 100) * cData.product.price));
                        price_ = price_.toFixed(2);
                    }
                }
                price_ = Number(price_) * Number(this.state.currency.rate);
                price_ = price_.toFixed(2);

                var measurements = " .";
                for (var m in cData.measurements) {
                    measurements += `${cData.measurements[m]['name']}:${cData.measurements[m]['mvalue']} ${cData.unit}|`;
                }

                if (cData.customermeasurements !== undefined) {
                    if (cData.customermeasurements != "") {
                        measurements = cData.customermeasurements;
                    }
                }

                if (cData.customermeasurements !== "") {
                    measurements = cData.customermeasurements;
                }

                var flow = [];
                for (var m in cData.flow) {
                    flow.push(`${cData.flow[m].image}`);
                }

                var payload = new URLSearchParams();
                payload.append("quantity", cData.quantity);
                payload.append("delivery", delivery_);
                payload.append("date", moment().format("YYYY-MM-DD HH:mm:ss"));
                payload.append("price", price_);
                payload.append("measurement", measurements);
                //console.log("cData.fabric.id:::", cData.fabric.id);
                if (cData.fabric.id !== "" && cData.fabric.id !== undefined)
                    payload.append("fabric_id", cData.fabric.id);
                // payload.append("fabric_color", 1);
                // if(flow!="")
                payload.append("flows", flow.join("|"));
                payload.append("order_id", order.id);
                payload.append("product_id", cData.product.id);
                payload.append("user_id", user.id);

                await PostData("invoices/", payload, false);
            }

            if (Number(this.state.payment) === 3)
                window.location = payment.data.authorization_url;
            if (Number(this.state.payment) === 4) {
                //console.log("payment::",payment);
                var approve = payment.links.filter(function (data) {
                    return data.rel === "approve";
                });


                if (approve[0].href !== undefined)
                    window.location = approve[0].href;
            }
        }
        this.setState({ loading: false });
    }

    render() {
        const { loading, currency, submitting } = this.state;
        const paymentOptions=currency.name==="GHS"?["Paystack"]:['Paypal'];

        return (
            <>
                <div className="header_sticky header-style-1 has-menu-extra">
                    <div className="boxed">{paymentOptions}
                        <Header />
                        <div className="page-title parallax parallax1">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="page-title-heading">
                                            <h1 className="title"></h1>
                                        </div>
                                        <div className="breadcrumbs">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className="flat-row flat-contact">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="title-section margin_bottom_17">
                                            <h4 className="title">
                                                Shipping details
                                            </h4>
                                            <p>Provide your shipping address details below.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="title-section margin_bottom_17">
                                            <h4 className="title">
                                                Payment Options
                                            </h4>
                                            <p style={{}}> Choose how you want to pay</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6" style={{ border: '1px solid #ededed', padding: 40 }}>
                                        <strong style={{ color: "#A00" }}>{this.state.errormsg}</strong>
                                        <div className='row registerForm'>
                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <input type="text" placeholder="First Name" aria-required="true" value={this.state.fname} onChange={(e) => this.setState({ fname: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <input type="text" placeholder="Last Name" aria-required="true" value={this.state.lname} onChange={(e) => this.setState({ lname: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <input type="email" placeholder="Email address" aria-required="true" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <select value={this.state.country} onChange={(e) => this.setState({ country: e.target.value })}>
                                                        <option value="">Select Country</option>
                                                        <option value="uk">UK</option>
                                                        <option value="usa">USA</option>
                                                        <option value="ghana">Ghana</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <input type="text" placeholder="Address" aria-required="true" value={this.state.address} onChange={(e) => this.setState({ address: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <input type="text" placeholder="City" aria-required="true" value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <input type="text" placeholder="State" aria-required="true" value={this.state.state} onChange={(e) => this.setState({ state: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <input type="text" placeholder="Zipcode" aria-required="true" value={this.state.zipcode} onChange={(e) => this.setState({ zipcode: e.target.value })} />
                                                </div>
                                            </div>

                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <input type="text" placeholder="Contact Number" aria-required="true" value={this.state.mobile} onChange={(e) => this.setState({ mobile: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="col-md-12 form-text-wrap clearfix" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <textarea placeholder="ORDER NOTES (OPTIONAL)" onChange={(e) => this.setState({ note: e.target.value })}>{this.state.note}</textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-5" style={{ border: '1px solid #ededed', padding: 40 }}>

                                        <div className="wrap-contact">
                                            <div className="col-md-12" style={{ marginTop: 10 }}>
                                                <h2 className="title">Checkout</h2>
                                                <hr />
                                                <ul className="popular-news clearfix">
                                                    <li>
                                                        <h6><a href="#">You will be charged the total amount below for this order.</a></h6>
                                                    </li>
                                                    <li>
                                                        <h3><a href="#">TOTAL : {currency.name} {this.state.total}</a></h3>
                                                    </li>
                                                </ul>

                                                <br /><br />
                                                <div className="">
                                                    <p>Select your payment method</p>
                                                    
                                                    <select onChange={(e) => this.setState({ payment: e.target.value })} >
                                                        <option value="">Select an option</option>
                                                        {this.state.paymentmethods
                                                        .map(data => {
                                                            if(paymentOptions.includes(data.title)){
                                                                return <option value={data.id}>{data.title}</option>
                                                            }
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className=" col-md-12 form-submit margin-top-32 " style={{ marginTop: 0 }}>
                                                {(loading == false) ?
                                                    <button className="contact-submit" onClick={() => this.placeOrder()}>Place Order</button> :
                                                    <Spinner bg={"#fff"} fill={"#000"} />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="flat-row mail-chimp">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="text">
                                            <h3>Sign up for Send Newsletter</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <Footer />
                    </div>
                </div>
            </>
        );
    }
}
import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import queryString, { stringify } from 'query-string';
import { GetData } from '../common';
import history from '../history';
import Spinner from '../components/Spinner';

export default class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cart: [],
            total: 0,
            loading: true,
            orders: {},
            invoices: [],
            currency: {},
            statuses: ["Pending", "Paid", "Payment Failed", "Delivered", "Cancelled"],

        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        this.setState({ loading: true });
        var cart = localStorage.getItem("cart");
        if (cart !== null) {
            cart = JSON.parse(cart);
            this.setState({ cart: cart });
        }

        var id = queryString.parse(this.props.location.search).id;
        var getDetails = await GetData(`orders/${id}/`);
        if (getDetails.code == 200) {
            this.setState({ orders: getDetails.message });
        }

        var getInvoice = await GetData(`invoices/?order_id=${id}`);
        if (getInvoice.code == 200) {
            var invoices = getInvoice.message.results;
            for (var i in invoices) {
                let invoice = invoices[i];
                var getProduct = await GetData(`products/${invoice.product_id}/`);
                if (getProduct.code == 200) {
                    invoices[i]['pdt'] = getProduct.message;
                }
            }
            this.setState({ invoices: invoices });
            console.log("invoices::", invoices);
        }
        this.setState({ loading: false });

    }

    render() {
        const { loading, orders, statuses } = this.state;


        return (
            <>
                <div className="header_sticky header-style-1 has-menu-extra">
                    <div className="boxed">
                        <Header />
                        <div className="page-title parallax parallax1">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="page-title-heading">
                                            {(loading == true) && <Spinner bg={"#f7f7f7"} fill={"#000"} />}
                                            <h1 className="title">ORDER #{this.state.orders.id}</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className="blog-posts style2">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-9 col-sm-12">
                                        <div className="post-wrap style2">
                                            {(this.state.invoices.map((data, index) => {
                                                var img = "";
                                                if (data.pdt.images != undefined) {
                                                    if (data.pdt.images[0] != undefined) {
                                                        img = data.pdt.images[0].image;
                                                    }
                                                }
                                                return <article className="post clearfix">
                                                    <div className="featured-post">
                                                        <img src={img} alt="image" width="200" />
                                                    </div>
                                                    <div className="content-post">
                                                        <div className="title-post">
                                                            <h2><a href="#">{data.pdt.name}</a></h2>
                                                        </div>
                                                        <ul className="meta-post">
                                                            <li className="author">
                                                                <span>Category: </span><a href="#">{data.pdt.subcategory_name}</a>
                                                            </li>
                                                        </ul>
                                                        <div className="title-post">
                                                            Quantity :  <input type="number" readonly value={data.quantity} style={{ width: 100 }} onChange={(e) => this.changeQuantity(index, e)} />
                                                        </div>
                                                        {(data.delivery != undefined) &&
                                                            <ul className="meta-post">
                                                                <li className="author">
                                                                    <span>Delivery : {this.state.orders.currency_name} </span><a href="#">{data.delivery}</a>
                                                                </li>
                                                            </ul>}
                                                        <br />
                                                        <div className="title-post">
                                                            <h3><a href="#">{this.state.orders.currency_name} {data.price}</a></h3>
                                                        </div>
                                                    </div>
                                                </article>
                                            }))}


                                            <ul className="popular-news clearfix mobile_order">
                                                <li>
                                                    <span>Delivery : </span><strong href="#">{orders.shipping_method_name}</strong>
                                                </li>
                                                <li>
                                                    <span>Delivery Addres : </span><strong href="#">{orders.user_address}</strong>
                                                </li>
                                                <li>
                                                    <span>Payment Method : </span><strong href="#">{orders.payment_method_name}</strong>
                                                </li>
                                                <li>
                                                    <span>Payment ID : </span><strong href="#">{orders.payment_id}</strong>
                                                </li>
                                                <li>
                                                    <span>Order Date : </span><strong href="#">{orders.ordering_date}</strong>
                                                </li>
                                                <li>
                                                    <span>Order Note: </span><strong href="#">{orders.comment}</strong>
                                                </li>
                                                <li>
                                                    <span>Order Status: </span><strong href="#">{statuses[orders.order_status]}</strong>
                                                </li>
                                                <li>
                                                    <span>Order Comment: </span><strong href="#">{statuses[orders.comment]}</strong>
                                                </li>
                                                <li>
                                                    <span>Order Remark: </span><strong href="#">{statuses[orders.remark]}</strong>
                                                </li>
                                                <li>
                                                    <h3><a href="#">TOTAL : {this.state.orders.currency_name} {this.state.orders.total_price}</a></h3>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="col-md-3 col-sm-12">
                                        <div className="sidebar">
                                            <div className="widget widget-news-latest">
                                                <h5 className="widget-title">Order Details</h5>
                                                <ul className="popular-news clearfix">
                                                    <li>
                                                        <span>Delivery : </span><strong href="#">{orders.shipping_method_name}</strong>
                                                    </li>
                                                    <li>
                                                        <span>Delivery Addres : </span><strong href="#">{orders.user_address}</strong>
                                                    </li>
                                                    <li>
                                                        <span>Payment Method : </span><strong href="#">{orders.payment_method_name}</strong>
                                                    </li>
                                                    <li>
                                                        <span>Payment ID : </span><strong href="#">{orders.payment_id}</strong>
                                                    </li>
                                                    <li>
                                                        <span>Order Date : </span><strong href="#">{orders.ordering_date}</strong>
                                                    </li>
                                                    <li>
                                                        <span>Order Note: </span><strong href="#">{orders.comment}</strong>
                                                    </li>
                                                    <li>
                                                        <span>Order Status: </span><strong href="#">{statuses[orders.order_status]}</strong>
                                                    </li>
                                                    <li>
                                                        <h3><a href="#">TOTAL : {this.state.orders.currency_name} {this.state.orders.total_price}</a></h3>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="product-detail">
                                                {/* <div className="add-to-cart">
                                                    <a href="/payment" style={{ cursor: 'pointer' }}>CHECKOUT</a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <Footer />
                    </div>
                </div>
            </>
        );
    }
}
import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import Parser from 'html-react-parser';
import { GetData } from '../common';

export default class Policy extends Component {
    constructor(props) {
        super(props)
        this.state = {
            content:null
        }
    }

    componentDidMount() {
        this.laodData();
    }

    laodData = async() => {
        var contentSlides = await GetData("content/?name=policy");
        if (contentSlides.code == 200) {
            this.setState({ content: contentSlides.message.results[0]});
        }
    }

    render() {
        return (
            <>
                <div className="header_sticky header-style-1 has-menu-extra">
                    <div className="boxed">
                        <Header />
                        <div className="page-title parallax parallax1">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="page-title-heading">
                                        {(this.state.content!==null)&&
                                            <h1 className="title">{this.state.content.title}</h1>
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <section className="blog-posts">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="post-wrap">

                                            <article className="post clearfix">
                                                <div className="content-post">

                                                {(this.state.content!==null)&&
                                                    <div className="entry-post">
                                                        {Parser(this.state.content.content)}
                                                    </div>}
                                                </div>
                                            </article>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </>
        );
    }
}
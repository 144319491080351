import axios from 'axios';

let baseURL = 'https://api.taussh.com/';
const http = axios.create({
    baseURL: baseURL,
});

//Api calls interceptor. It will intercept all request and set their headers
http.interceptors.request.use(
    async config => {
        let token = localStorage.getItem("jwtToken");
        if (token) {
            config.headers.Authorization = `${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

http.interceptors.response.use(async response => {
    if (response?.data?.code === 401) {
        const originalRequest = response.config;
        originalRequest._retry = true;
        const user = await localStorage.getItem('customer');
        const user_ = JSON.parse(user);

        if (!user) {
            const access_token = await axios.post(baseURL + 'api/jwt/token/', {
                username: 'website',
                password: 'admin'
            });
            if (access_token?.status === 200) {
                localStorage.setItem('jwtToken', access_token?.data?.tokens?.access);
                originalRequest.headers.Authorization =
                    access_token?.data?.access;
            }
            return http(originalRequest);
        }
    }

    return response;
});

export default http;

import React, { Component } from 'react';
import { currency, GetData } from '../common';
import history from '../history';
import axios from 'axios';

export default class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menu: [],
            customer: {},
            cart: [],
            currency: "usd",
            currencyList: [],
            user: false,
            menuloading: true,
            mobile: false
        }
    }

    componentDidMount() {
        localStorage.setItem("jwtToken", "");
        this.setState({ mobile: (window.innerWidth <= 768) ? true : false }, () => {
        })
        this.loadData();
    }


    loadData = async () => {
        var user = localStorage.getItem("customer");
        this.setState({ user: (user == null) ? false : true });
        var currency = await GetData("currency/");
        if (currency.code == 200) {
            this.setState({ currencyList: currency.message.results });
        }


        var currencys = localStorage.getItem("currency");
        if (currencys !== null) {
            this.setState({ currency: JSON.parse(currencys) });
        } else {
            let chosen = false;
            if (window.navigator.geolocation) {
                await axios.get('https://ipapi.co/json/').then((response) => {
                    let data = response.data;
                    //console.log("Geo data::",response.data.currency_name);
                    let currency = response.data.currency;
                    var defaultCurrent = currency.message.results.filter(function (data) {
                        return data.name.toUpperCase() == currency;
                    });
                    if (defaultCurrent[0] != undefined) {
                        localStorage.setItem("currency", JSON.stringify(defaultCurrent[0]));
                        this.setState({ currency: defaultCurrent[0] });
                        chosen = true;
                    }
                }).catch((error) => {
                    //console.log(error);
                });
            }

            if (chosen === false) {
                var defaultCurrent = currency.message.results.filter(function (data) {
                    return data.name.toLowerCase() == "usd";
                });
                if (defaultCurrent[0] != undefined) {
                    localStorage.setItem("currency", JSON.stringify(defaultCurrent[0]));
                    this.setState({ currency: defaultCurrent[0] });
                }
            }
        }



        var cart = localStorage.getItem("cart");
        if (cart !== null) {
            cart = JSON.parse(cart);
            this.setState({ cart: cart });
        }
        var getmenu = await GetData("menu/?is_active=1");
        if (getmenu.message.code == "token_not_valid") {
            localStorage.removeItem("jwtToken");
            getmenu = await GetData("menu/?is_active=1");
        }
        if (getmenu.code == 200) {
            this.setState({ menu: getmenu.message.results });
            var menus = getmenu.message.results;
            for (var m in menus) {
                const data = menus[m];

                var submenu = await GetData("categories/?is_active=1&parent_id=" + data.id);
                if (getmenu.code == 200) {
                    var categories = submenu.message.results;
                    for (var n in categories) {
                        var submenu = await GetData("subcategories/?is_active=1&parent_id=" + categories[n].id);
                        categories[n]['subcategory'] = submenu.message.results;
                    }
                    menus[m]['category'] = categories;
                }
            }

            this.setState({ menu: menus, menuloading: false });


        }

        var customerData = localStorage.getItem("customer");
        if (customerData != null) {
            this.setState({ customer: JSON.parse(customerData) });
        }
    }

    logoutFunction = async () => {
        localStorage.removeItem("tokenDetails");
        localStorage.removeItem("customer");
        localStorage.removeItem("loginNav");
        localStorage.removeItem("cart");

        history.push("/login");
        window.location = window.location.href;
    }

    setCurrency = async (currency) => {
        this.setState({ currency: currency });
        localStorage.setItem("currency", JSON.stringify(currency));
        window.location.reload();
    }

    render() {
        const { menu, customer, currency, menuloading, user, cart, mobile } = this.state;

        let menuList = [];
        if (menuloading == false) {
            for (var m in menu) {
                let cat = [];
                for (var n in menu[m].category) {
                    var subcat = [];
                    for (var a in menu[m].category[n].subcategory) {
                        subcat.push(<li><a href={`/catalog?is_active=1&subcategory=${menu[m].category[n].subcategory[a].id}`}>{menu[m].category[n].subcategory[a].name}</a></li>);
                    }
                    if (subcat.length > 0) {
                        cat.push(<li>
                            <a href={`/catalog?is_active=1&category=${menu[m].category[n].id}`}>{menu[m].category[n].name}</a>
                            <span className="btn-submenu"></span>
                            <ul className="submenu" style={{ display: mobile === true ? "none" : "block" }}>
                                {subcat}
                            </ul>
                        </li>);
                    }
                }

                var subs = [];
                if (cat.length > 0) {
                    subs.push(<ul className="submenu" style={{ display: mobile === true ? "none" : "block" }}>
                        {cat}
                    </ul>);
                }
                menuList.push(<li>
                    <a href={`/catalog?is_active=1&menu=${menu[m].id}`} className="text-white">{menu[m].name.toUpperCase()}</a>
                    <span className="btn-submenu"></span>
                    {subs}
                </li>);
            }
        }

        return (
            <div id="site-header-wrap" style={{ background: '#000' }}>
                <header id="header" className="header clearfix">
                    <div className="container-fluid clearfix container-width-93" id="site-header-inner">
                        <div id="logo" className="logo float-left">
                            <a href="/" title="logo">
                                <img src="images/logo.png" alt="image" width="107" height="24" data-retina="images/logo@2x.png" data-width="107" data-height="24" />
                            </a>
                        </div>
                        <div className="mobile-button"><span></span></div>
                        <ul className="menu-extra">
                            <li className="box-login nav-top-cart-wrapper">
                                <a className="">{(this.state.currency.image !== undefined) ? <img src={this.state.currency.image} width={24} /> : ""}</a>
                                <div className="nav-shop-cart">
                                    <div className="widget_shopping_cart_content">
                                        <div className="woocommerce-min-cart-wrap">
                                            <ul className="woocommerce-mini-cart cart_list product_list_widget ">
                                                {this.state.currencyList.map((curr, index) => {
                                                    return <li key={index}>
                                                        <a onClick={() => this.setCurrency(curr)} style={{ color: '#222', width: '100%', borderRadius: 0 }}><img src={curr.image} width={24} /></a>
                                                    </li>
                                                })}

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="box-login nav-top-cart-wrapper">
                                <a className="icon_login" href={(user === true) ? "/profile" : "/login"}></a>
                                {user == true &&
                                    <div className="nav-shop-cart">
                                        <div className="widget_shopping_cart_content">
                                            <div className="woocommerce-min-cart-wrap">
                                                <ul className="woocommerce-mini-cart cart_list product_list_widget ">
                                                    <li>
                                                        <a href={`/profile`} style={{ color: '#222', width: '100%', borderRadius: 0 }}>PROFILE</a>
                                                    </li>
                                                    <li>
                                                        <a href={`/orders`} style={{ color: '#222', width: '100%', borderRadius: 0 }}>MY ORDERS</a>
                                                    </li>
                                                    <li>
                                                        <a href={`/logout`} style={{ color: '#222', width: '100%', borderRadius: 0 }}>LOGOUT</a>
                                                    </li>
                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                }
                            </li>
                            <li className="box-cart nav-top-cart-wrapper">
                                <a className="icon_cart nav-cart-trigger active" href="#"><span>{cart.length}</span></a>
                                <div className="nav-shop-cart">
                                    <div className="widget_shopping_cart_content">
                                        <div className="woocommerce-min-cart-wrap">
                                            <ul className="woocommerce-mini-cart cart_list product_list_widget ">
                                                {cart.length === 0 &&
                                                    <li className="woocommerce-mini-cart-item mini_cart_item">
                                                        <span>No Items in Shopping Cart</span>
                                                    </li>
                                                }
                                                {cart.map(data => {
                                                    let discount = 0;
                                                    let img = "";
                                                    if (data.discount > 0) {
                                                        discount = (data.product.price - ((data.discount / 100) * data.product.price));
                                                        discount = discount.toFixed(2);
                                                    }

                                                    if (data.product.images.length > 0)
                                                        img = data.product.images[0].image;

                                                    let price_ = (data.discount > 0) ? discount : data.product.price;
                                                    let priceConverted = Number(price_) * Number(currency.rate);
                                                    let Normalprice = Number(data.product.price) * Number(currency.rate);
                                                    Normalprice = Normalprice.toFixed(2);
                                                    priceConverted = priceConverted.toFixed(2);

                                                    return <li className="woocommerce-mini-cart-item mini_cart_item">
                                                        <div className='row'>
                                                            <div className='col-4'>
                                                                <img src={img} width="100%" />
                                                            </div>
                                                            <div className='col-8'>
                                                                <span>{data.product.name}</span><br />
                                                                <span>Quantity : {data.quantity}</span><br />
                                                                <strong>Price : {currency.name + " " + priceConverted}</strong>
                                                            </div>
                                                        </div>
                                                    </li>
                                                })}

                                                {cart.length > 0 &&
                                                    <li>
                                                        <a href={`/checkout`} style={{ background: '#444', border: '1px solid #999', color: '#fff', width: '100%', borderRadius: 0 }}>CHECKOUT</a>
                                                    </li>}
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div className="nav-wrap">
                            <nav id="mainnav" className="mainnav">
                                <ul className="menu">
                                    {menuList}
                                    <li>
                                        <a href="/catalog" className="text-white">CATALOG</a>
                                    </li>
                                    <li>
                                        <a href="/blogs" className="text-white">BLOG</a>
                                    </li>
                                    <li >
                                        <a href="/about" className="text-white">ABOUT US</a>
                                    </li>
                                    <li>
                                        <a href="/contact" className="text-white">CONTACTS</a>
                                    </li>

                                    {(user == true && mobile == true) &&
                                        <li className="mobileonly">
                                            <a href="/checkout" style={{ width: '100%' }}>CHECKOUT</a>
                                        </li>}
                                    {(user == true && mobile == true) &&
                                        <li className="mobileonly">
                                            <a href="/profile" style={{ width: '100%' }}>PROFILE</a>
                                        </li>}
                                    {(user == true && mobile == true) &&
                                        <li className="mobileonly">
                                            <a href="/orders" style={{ width: '100%' }}>MY ORDERS</a>
                                        </li>}
                                    {(user == true && mobile == true) &&
                                        <li className="mobileonly">
                                            <a href={`/logout`} style={{ width: '100%' }}>LOGOUT</a>
                                        </li>}
                                    {(mobile == true) &&
                                        <li>
                                            <div className='row'>
                                                {this.state.currencyList.map((curr, index) => {
                                                    return <div className='col-4 text-center' key={index}>
                                                        <a onClick={() => this.setCurrency(curr)} style={{ color: '#222', width: '100%', borderRadius: 0 }}><img src={curr.image} width={24} /></a>
                                                    </div>
                                                })}
                                            </div>
                                        </li>
                                    }
                                </ul>
                            </nav>
                        </div>
                    </div>
                </header>
            </div>

        );
    }
}
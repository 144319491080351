import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import { GetData, getUserData, PatchData, PostData, PutData } from '../common';
import history from '../history';
import Spinner from '../components/Spinner';

export default class Contact extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fname: "",
            lname: "",
            email: "",
            dob: "",
            mobile: "",
            username: "",
            password: "",
            gender: 1,
            country: "",
            city: "",
            state: "",
            zipcode: "",
            address: "",
            errormsg: "",
            loginerrormsg: "",
            loading: false,
            login_username: "",
            login_password: "th*mas024",
            confirmpassword:"",
            login_confirm_password:"th*mas024",
            current_password:"timmyses",
            loading1:false
        }
    }

    componentDidMount() {
        this.laodData();
    }

    laodData = async() => {
        var getUser=await getUserData();
        // console.log(">>>>>",getUser);
        this.setState({
            email:getUser.email,
            fname:getUser.first_name,
            lname:getUser.last_name,
            address:getUser.address,
            city: getUser.city,
            state: getUser.state,
            zipcode: getUser.zip,
            country:getUser.country,
            dob:getUser.date_of_birth
        })
    }

    registerAccount = async () => {
        if (this.state.fname == "") {
            this.setState({ errormsg: "First name is required" });
        } else if (this.state.lname == "") {
            this.setState({ errormsg: "Last name is required" });
        } else if (this.state.email == "") {
            this.setState({ errormsg: "Email address is required" });
        } else if (this.state.dob == "") {
            this.setState({ errormsg: "Date of birth is required" });
        } else if (this.state.country == "") {
            this.setState({ errormsg: "Country is required" });
        } else if (this.state.address == "") {
            this.setState({ errormsg: "Address is required" });
        } else if (this.state.city == "") {
            this.setState({ errormsg: "City is required" });
        } else if (this.state.zipcode == "") {
            this.setState({ errormsg: "Zipcode is required" });
        } else {
            var getUser=await getUserData();
            var payload = new URLSearchParams();
            payload.append('first_name', this.state.fname);
            payload.append('last_name', this.state.lname);
            payload.append('email', this.state.email);
            payload.append('mobile', this.state.mobile);
            payload.append('country', this.state.country);
            payload.append('city', this.state.city);
            payload.append('zipcode', this.state.zipcode);
            payload.append('address', this.state.address);
            payload.append('state', "");
            payload.append('gender', this.state.gender);
            payload.append('date_of_birth', this.state.dob);
            payload.append('groups', 2);

            this.setState({loading1:true});
            var user = await PatchData(`users/${getUser.id}/`, payload, false);
            if (user.code == 200) {
                var getUser = await GetData(`users/?username=${getUser.username}`);
                // console.log("getUser::>>>",getUser);
                localStorage.setItem("customer", JSON.stringify(getUser.message.results[0]));
                
                this.setState({ errormsg: "Profile updated successfully" });
                this.laodData();
            }else if (user.code == 401) {
                // this.registerAccount();
            } else {
                var errors = "";
                for (var e in user.message) {
                    const data = user.message[e];
                    for (var d in data) {
                        errors += `${data[d]}`;
                    }
                }
                this.setState({ errormsg: errors });
            }
            // console.log(user);
            this.setState({loading1:false});
        }
    }

    loginFunction = async () => {
        if (this.state.login_password == "") {
            this.setState({ loginerrormsg: "Password is required" });
        } else if (this.state.login_password != this.state.login_confirm_password) {
            this.setState({ loginerrormsg: "Passwords do not match" });
        } else {
            var formData = new URLSearchParams();
            formData.append("email", this.state.email);
            formData.append("old_password", this.state.current_password);
            formData.append("new_password", this.state.login_password);
            var getToken = await PutData("change_password/", formData, false);
            if (getToken.code == 200) {
                this.setState({ loginerrormsg: getToken.message });
            }else{
                var errors = "";
                for (var e in getToken.message) {
                    const data = getToken.message[e];
                    for (var d in data) {
                        errors += `${data[d]}`;
                    }
                }
                this.setState({ loginerrormsg: errors });
            }
        }
    }

    render() {
        const { loading,loading1 } = this.state;
        return (
            <>
                <div className="header_sticky header-style-1 has-menu-extra">
                    <div className="boxed">
                        <Header />
                        <div className="page-title parallax parallax1">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="page-title-heading">
                                            <h1 className="title"></h1>
                                        </div>
                                        <div className="breadcrumbs">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className="flat-row flat-contact">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="title-section margin_bottom_17">
                                            <h4 className="title">
                                                Profile
                                            </h4>
                                            <p>Update your account details.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="title-section margin_bottom_17">
                                            <h4 className="title">
                                                Password Reset
                                            </h4>
                                            <p>Reset your password</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-7" style={{ border: '1px solid #ededed', padding: 40 }}>
                                        <strong style={{ color: "#A00" }}>{this.state.errormsg}</strong>
                                        <div className='row registerForm'>
                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <input type="text" placeholder="First Name" aria-required="true" value={this.state.fname} onChange={(e) => this.setState({ fname: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <input type="text" placeholder="Last Name" aria-required="true" value={this.state.lname} onChange={(e) => this.setState({ lname: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <select value={this.state.gender} onChange={(e) => this.setState({ gender: e.target.value })}>
                                                        <option value="">Select Gender</option>
                                                        <option value="1">Male</option>
                                                        <option value="2">Female</option>
                                                        <option value="3">Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <input type="date" placeholder="Date of birth" aria-required="true" value={this.state.dob} onChange={(e) => this.setState({ dob: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <input type="text" placeholder="Email Address" aria-required="true" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <input type="text" placeholder="Address" aria-required="true" value={this.state.address} onChange={(e) => this.setState({ address: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <input type="text" placeholder="City" aria-required="true" value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <input type="text" placeholder="State" aria-required="true" value={this.state.state} onChange={(e) => this.setState({ state: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <input type="text" placeholder="Zipcode" aria-required="true" value={this.state.zipcode} onChange={(e) => this.setState({ zipcode: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{ marginTop: 10 }}>
                                                <div className="">
                                                    <select value={this.state.country} onChange={(e) => this.setState({ country: e.target.value })}>
                                                        <option value="">Select Country</option>
                                                        <option value="uk">UK</option>
                                                        <option value="usa">USA</option>
                                                        <option value="ghana">Ghana</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className=" col-md-12 form-submit margin-top-32 " style={{ marginTop: 10 }}>
                                                {(loading1 == false) ?
                                                    <button className="contact-submit" onClick={() => this.registerAccount()}>Update Account</button> :
                                                    <Spinner bg={"#fff"} fill={"#000"} />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-4">
                                        <strong style={{ color: "#A00",marginBottom:20 }}>{this.state.loginerrormsg}</strong>
                                        
                                        <div className="wrap-contact">
                                            <form novalidate="" className="contact-form" id="contactform" method="post" action="#">
                                                <div className="form-text-wrap clearfix">
                                                    <div className="">
                                                        <input type="password" placeholder="Current Password" aria-required="true" value={this.state.current_password} onChange={(e) => this.setState({ current_password: e.target.value })} />
                                                    </div>
                                                    <br />
                                                    <div className="">
                                                        <input type="password" placeholder="Password" aria-required="true" value={this.state.login_password} onChange={(e) => this.setState({ login_password: e.target.value })} />
                                                    </div>
                                                    <br />
                                                    <div className="">
                                                        <input type="password" placeholder="Confirm Password" value={this.state.login_confirm_password} onChange={(e) => this.setState({ login_confirm_password: e.target.value })} />
                                                    </div>
                                                </div>
                                                <div className="form-submit margin-top-32 ">
                                                    {(loading == false) ?
                                                    <button type="button" className="contact-submit" onClick={() => this.loginFunction()}>RESET PASSWORD</button> :
                                                    <Spinner bg={"#f7f7f7"} fill={"#000"} />}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="flat-row mail-chimp">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="text">
                                            <h3>Sign up for Send Newsletter</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <Footer />
                    </div>
                </div>
            </>
        );
    }
}
import React, { Component } from "react"
import Cookie from "js-cookie"
import styled from "styled-components"
import media from "styled-media-query"

const Background = styled.div`
  position: fixed;
  z-index: 9997;
  right: 0;
  bottom: -200px;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
`

const CookieContainer = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  
  left: 0;
  z-index: 9998;
  vertical-align: middle;
  white-space: nowrap;
  max-height: 100%;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  font-size: 14px;
  overflow-y: scroll;
  :after {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
`

const CookieInnerContainer = styled.div`
  width: var(--content-width);
  height: auto;
  max-width: none;
  border-radius: var(--border-radius);
  display: inline-block;
  z-index: 9999;
  background-color: var(--content-bg);
  background:#fff;
  text-align: left;
  white-space: normal;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 20%);
  position: relative;
  border: 1px solid var(--body-bg);
  vertical-align: middle;
  padding:40px;
  ${media.lessThan("medium")`
    width: 90%;
  `}
`

const Wrapper = styled.div`
  max-height: 100%;
  height: auto;
  max-width: none;
  text-align: center;
  border-radius: 16px;
  display: inline-block;
  margn-bottom:20px;
  text-align: left;
  white-space: normal;
`

const CookieHeader = styled.div`
  padding: var(--space);
  display: flex;
  justify-content: space-between;
`

const CookieBody = styled.div``

const CookieContentContainer = styled.div``

const CookieContentBlock = styled.div`
  margin-bottom: var(--space-sm);
  margin-top: var(--space);
`

const CookieTextList = styled.ul`
  margin: 0;
  padding: 0;
  padding-inline-start: 1rem;
`

const CookieTextItem = styled.li`
  margin: var(--space-sm) 0;
`

const CookieBannerText = styled.div`
  padding: 0 var(--space);
`

const CookieHeadline = styled.h1`
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: var(--space);
`

const Text = styled.div`
  margin-bottom: var(--space-sm);
  margin-top: var(--space);
`

const CookieLink = styled.a`
  border-bottom: 1px solid var(--text-color);
  :hover {
    border-bottom: none;
  }
  cursor: pointer;
  margin-right: var(--space-sm);
`

const TextLink = styled.a`
  border-bottom: 1px solid var(--text-color);
  :hover {
    border-bottom: none;
  }
`

const List = styled.ol`
  list-style: none;
  padding-inline-start: 0;
  display: flex;
`

const Socialtem = styled.li`
  margin: var(--space-sm) var(--space-sm) var(--space-sm) 0;
  transition: 0.2s;
  :hover {
    color: var(--secondary-color);
    cursor: pointer;
  }
`

const ButtonContainer = styled.div`
  margin: var(--space);
  margin-top:20px;
  display: flex;
  justify-content: space-between;
  ${media.lessThan("medium")`
    flex-direction: column;
    gap: var(--space-sm);
  `}
`

class CookieBanner extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false,
    }
  }

  componentDidMount() {
    const { debug } = this.props
    // if cookie undefined or debug
    if (Cookie.get("consent") === undefined || debug) {
      document.body.style.overflow = "hidden"
      this.setState({ visible: true })
    }
  }

  componentDidUpdate() {
    const { debug } = this.props
    if (
      window.location.href.includes("privacy-policy") ||
      window.location.href.includes("site-notice")
    ) {
      document.body.style.overflow = "scroll"
    } else if (Cookie.get("consent") === undefined || debug) {
      document.body.style.overflow = "hidden"
    }
  }

  accept = () => {
    Cookie.set("consent", true, { sameSite: "strict", expires: 365 })
    //enableGoogleAnalytics();
    //enableGoogleAdsense();
    this.setState({ visible: false })
    document.body.style.overflow = "scroll"
  }

  decline = () => {
    Cookie.set("consent", false, { sameSite: "strict", expires: 365 })
    //window['ga-disable-GA_MEASUREMENT_ID'] = true;
    this.setState({ visible: false })
    document.body.style.overflow = "scroll"
  }

  render() {
    if (
      !this.state.visible ||
      window.location.href.includes("privacy-policy") ||
      window.location.href.includes("site-notice")
    ) {
      return null
    }

    return (
      <>
        <Background />
        <CookieContainer>
          <CookieInnerContainer>
            <Wrapper>
              <CookieHeader>
                
              </CookieHeader>

              <CookieBannerText>
                <CookieHeadline>GDPR Cookies Consent</CookieHeadline>
                <CookieContentBlock>
                  You can easily support me by accepting optional (third-party)
                  cookies. These cookies will help with the following:
                  <CookieTextList>
                    <CookieTextItem>
                      Collect audience interaction data and site statistics
                    </CookieTextItem>
                    <CookieTextItem>
                      Deliver advertisements and measure the effectiveness of
                      advertisements
                    </CookieTextItem>
                    <CookieTextItem>
                      Show personalized content (depending on your settings)
                    </CookieTextItem>
                  </CookieTextList>
                </CookieContentBlock>
                
                <a href="/policy">
                  <CookieLink>Privacy Policy</CookieLink>
                </a>
                &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                <a href="/terms">
                  <CookieLink>Terms And Conditions</CookieLink>
                </a>
              </CookieBannerText>

              <ButtonContainer>
                <button
                  onClick={() => {
                    this.accept()
                  }}
                >
                  Accept required and optional cookies
                </button>
                <button
                  onClick={() => {
                    this.decline()
                  }}
                  backgroundColor="var(--content-bg)"
                  color="#70757a"
                >
                  Accept required cookies
                </button>
              </ButtonContainer>
            </Wrapper>
          </CookieInnerContainer>
        </CookieContainer>
      </>
    )
  }
}

export default CookieBanner
import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import queryString, { } from 'query-string';
import { GetData, PatchData, PostData } from '../common';
import history from '../history';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal } from 'react-bootstrap';
import Spinner from '../components/Spinner';

export default class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            product: {},
            fabrics: [],
            fabric: { id: "" },
            flows: [],
            flow: {},
            active: 0,
            activeFlow: null,
            customizationLabel: "test",
            measurements: [],
            measurementDisplay: {},
            mvalue: 0,
            delivery: "",
            deliveryoption: "",
            currency: { name: "USD" },
            custom_measurement: true,
            customermeasurements: "",
            addedtxt: "",
            measurementunit: "inches",
            units: ["inches", "cm"],
            img_list: new Set(),
            take_measurements: false,
            preview: false,
            savemeasurement_error: "",
            selectedtab:""
        }
    }

    componentDidMount() {
        this.getProducts();
        this.loadData();
    }

    loadData = async => {
        var currency = localStorage.getItem("currency");
        if (currency !== null) {
            this.setState({ currency: JSON.parse(currency) });
        }
    }

    checkout = async (navType) => {
        var measurement_check = true;
        for (var m in this.state.measurements) {
            if (this.state.measurements[m].mvalue === undefined) {
                measurement_check = false;
            }
        }

        if (this.state.delivery === "") {
            this.setState({ errorMsg: "Kindly select a delivery option for this item" });
        } else if (measurement_check === false) {
            this.setState({ errorMsg: "Kindly fill all measurements" });
        } else {
            this.setState({ errorMsg: "" });
            var user = localStorage.getItem("customer");
            var cart = localStorage.getItem("cart");
            if (cart !== null)
                cart = JSON.parse(cart);
            else
                cart = [];

            var product_ = {};
            product_['product'] = this.state.product;
            product_['fabric'] = this.state.fabric;
            product_['measurements'] = this.state.measurements;
            product_['unit'] = this.state.measurementunit;
            product_['customermeasurements'] = this.state.customermeasurements;
            product_['flow'] = this.state.flow;
            product_['quantity'] = 1;
            product_['delivery'] = this.state.delivery;

            var found = null;
            for (var c in cart) {
                const data = cart[c];
                if (data.product.id === this.state.product.id) {
                    found = c;
                }
            }
            if (found === null)
                cart.push(product_);
            else
                cart[found] = product_;

            localStorage.setItem("cart", JSON.stringify(cart));
            if (user === null) {
                localStorage.setItem("loginNav", "/checkout");
                history.push("/checkout");
                window.location = window.location.href;
            } else {
                if (navType === true) {
                    this.setState({ addedtxt: "Product added to cart , continue shopping" });
                } else {
                    history.push("/checkout");
                    window.location = window.location.href;
                }
            }
            this.setState({ preview: false });
        }
    }

    getProducts = async () => {
        this.setState({ loading: true });
        var id = queryString.parse(this.props.location.search).id;
        var getmenu = await GetData(`products/${id}/`);
        if (getmenu.message.code === "token_not_valid") {
            localStorage.removeItem("jwtToken");
            getmenu = await GetData(`products/${id}/`);
        }

        if (getmenu.code === 200) {
            this.setState({ product: getmenu.message });
        }

        this.getFabrics();
        this.getFlows();
        this.getMeasurement();
        this.reRender(0);
        this.setState({ loading: false });
    }

    reRender = (id) => {
        setTimeout(function () {
            window.$('#carousel_' + id).flexslider({
                animation: "slide",
                controlNav: false,
                animationLoop: false,
                slideshow: false,
                itemWidth: 180,
                itemMargin: 5,
                asNavFor: '#slider_' + id
            });

            window.$('#slider_' + id).flexslider({
                animation: "slide",
                controlNav: false,
                animationLoop: false,
                slideshow: false,
                sync: "#carousel_" + id
            });
        }, 500);
    }

    getFabrics = async () => {
        var id = queryString.parse(this.props.location.search).id;
        var productfabricassignment = await GetData(`productfabricassignment/?product=${id}`);
        if (productfabricassignment.code === 200) {
            if (productfabricassignment.message !== "undefined") {
                if (productfabricassignment.message.results !== "undefined") {
                    // this.setState({fabrics:});
                    if (productfabricassignment.message.results.length > 0) {

                        var fabric = [];
                        let fabricList = await GetData("fabrics/?is_active=true");
                        fabric = fabric.concat(fabricList.message.results);
                        while (fabricList.message.next != null) {
                            var page = fabricList.message.next.split("?");
                            fabricList = await GetData(`fabrics/?${page[1]}`);

                            fabric = fabric.concat(fabricList.message.results);
                        }

                        var active_fabrics = productfabricassignment.message.results.map((data) => {
                            return data.fabric;
                        });

                        if (fabric.length > 0) {
                            fabric = fabric.filter(data => {
                                return active_fabrics.includes(data.id);
                            });
                            this.setState({ fabrics: fabric });
                        }
                    }
                }
            }
        }
    }

    getFlows = async () => {
        var id = queryString.parse(this.props.location.search).id;
        var productfabricassignment = await GetData("productflowassignment/?product_id=" + id);
        if (productfabricassignment.code === 200) {
            if (productfabricassignment.message !== undefined) {
                if (productfabricassignment.message.results !== undefined) {
                    // this.setState({fabrics:});
                    if (productfabricassignment.message.results.length > 0) {
                        var fabric = [];
                        let fabricList = await GetData("flows/");
                        fabric = fabric.concat(fabricList.message.results);
                        while (fabricList.message.next != null) {
                            var page = fabricList.message.next.split("?");
                            fabricList = await GetData(`flows/?${page[1]}`);

                            fabric = fabric.concat(fabricList.message.results);
                        }

                        var active_fabrics = productfabricassignment.message.results.map((data) => {
                            return data.flow_id;
                        });

                        if (fabric.length > 0) {
                            fabric = fabric.filter(data => {
                                return active_fabrics.includes(data.id);
                            });

                            this.setState({ flows: fabric });
                        }
                    }
                }
            }
        }
    }

    getMeasurement = async () => {
        var user = localStorage.getItem("customer");
        if (user !== null)
            user = JSON.parse(user);

        var id = queryString.parse(this.props.location.search).id;
        var productmeasurementassignment = await GetData("productmeasurementassignment/?product_id=" + id);

        if (productmeasurementassignment.code === 200) {
            if (productmeasurementassignment.message !== undefined) {
                if (productmeasurementassignment.message.results !== undefined) {
                    if (productmeasurementassignment.message.results.length > 0) {
                        var measurementdetails = await GetData("measurementdetails/?is_active=true&header=" + productmeasurementassignment.message.results[0].measurement_id);

                        let mList = measurementdetails.message.results;

                        if (user !== null) {
                            var checkedSaved = await GetData(`measurementprofile/?user_id=${user.id}&measurement_header=${productmeasurementassignment.message.results[0].measurement_id}`, true);
                            let savedMesaurements = checkedSaved.message.results;
                            let unitalue = "";
                            for (var mL in mList) {
                                const mData = mList[mL];
                                const filter = savedMesaurements.filter(data => data.label == mData.name);
                                if (filter.length > 0) {
                                    mList[mL].mvalue = filter[0].measurement;
                                    unitalue = filter[0].unit;
                                }
                            }
                            if (unitalue !== "")
                                this.setState({ measurementunit: unitalue });
                        }

                        this.setState({ measurements: mList, custom_measurement: true });
                    }
                }
            }
        }
    }

    selectFlow = async (id, item) => {
        var flow = this.state.flow;
        flow[id] = item;
        this.setState({ flow: flow });

        var img_list = this.state.img_list;
        img_list.clear();
        for (var f in flow) {
            img_list.add(Number(flow[f].id));
        }
        this.setState({ img_list: img_list });
    }

    removeFlow = async (item) => {
        var flow = this.state.flow;
        var newFlow = {};
        for (var f in flow) {
            if (f !== item) {
                newFlow[f] = flow[f];
            }
        }
        this.setState({ flow: newFlow });
    }

    checkFlow(img) {
        var result = false;
        for (var f in this.state.flow) {
            const data = this.state.flow[f];

            if (data.image === img)
                result = true;
        }
        return result;
    }

    saveMeasurements = async () => {
        var measurement_check = true;


        for (var m in this.state.measurements) {
            if (this.state.measurements[m].mvalue === undefined) {
                measurement_check = false;
            }
        }

        if (measurement_check === false) {
            this.setState({ savemeasurement_error: "Kindly fill in all the measurement fields to continue" });
            setTimeout(() => {
                this.setState({ savemeasurement_error: "" });
            }, 3000);
        } else {
            var user = localStorage.getItem("customer");
            user = JSON.parse(user);
            var measurement = await GetData(`measurementprofile/?user_id=${user.id}&measurement_header=${this.state.measurements[m].header}`, true);
            measurement = measurement.message.results;
            for (var m in this.state.measurements) {
                var payload = new URLSearchParams();
                payload.append("measurement", this.state.measurements[m].mvalue);
                payload.append("label", this.state.measurements[m].name);
                payload.append("unit", this.state.measurementunit);

                //check and update or create new
                const filter = measurement.filter(data => data.label == this.state.measurements[m].name);
                if (filter.length == 0) {
                    payload.append("status", true);
                    payload.append("measurement_header", this.state.measurements[m].header);
                    payload.append("user_id", user.id);
                    await PostData("measurementprofile/", payload, true);
                } else {
                    await PatchData(`measurementprofile/${filter[0].id}/`, payload, true);
                }
            }
        }
    }

    render() {
        const { loading, savemeasurement_error, preview, take_measurements, units, measurementunit, addedtxt, custom_measurement, currency, product, fabrics, fabric, flows, flow, active, measurements } = this.state;
        console.log("measurements::", measurements);
        var size = [];
        if (product.sizes !== undefined && product.sizes !== null) {
            var loopSize = product.sizes.split("|");
            if (product.sizes !== "null") {
                for (var s in loopSize) {
                    size.push(<li><a href="#">{loopSize[s]}</a></li>);
                }
            }
        }
        let pdtImage = [];
        if (product.images !== undefined) {
            if (product.images[0] !== undefined) {
                pdtImage = <img src={product.images[0].image} />
            }
        }
        console.log(this.state.img_list);
        let customization = [];

        customization.push(<div className="" style={{ display: (active === 0) ? "block" : "none" }}>
            <div id={"slider_0"} className="flexslider" style={{ height: 330, overflow: 'hidden' }}>
                <ul className="slides">
                    {(fabrics.map((img) => {
                        return <li style={{ position: 'relative' }}>
                            <a onClick={() => {
                                this.setState({ fabric: img });
                            }}>
                                <img src={img.image} />
                            </a>
                            {(fabric.id !== img.id) ? <a className="selectBtn" onClick={() => this.setState({ fabric: img })} style={{ cursor: 'pointer', position: 'absolute', top: 10, right: 0, background: 'rgba(0,0,0,0.8)', color: '#fff', fontSize: 14, padding: '5px 20px', fontWeight: 'bold' }}>Click to select</a> :
                                <div className="selectBtn" style={{ position: 'absolute', top: 10, right: 0, background: 'rgba(255,0,0,0.8)', color: '#fff', fontSize: 14, padding: '5px 20px', fontWeight: 'bold' }}>SELECTED</div>}
                        </li>
                    }))}
                </ul>
            </div>
            <div id="carousel_0" className="flexslider">
                <ul className="slides">
                    {(fabrics.map((img) => {
                        return <li style={{ width: 'auto', height: 120, overflow: 'hidden', borderRadius: 5 }}>
                            <img src={img.image} />
                        </li>
                    }))}
                </ul>
            </div>
        </div>);

        if (this.state.custom_measurement === true) {
            customization.push(<div className="row" style={{ display: (active === 99999) ? "block" : "none" }}>
                <div className='col-12 text-center' style={{ padding: 20 }}>
                    <img src={this.state.measurementDisplay.image} height={150} />
                </div>
                <div className='col-12'>
                    <div className="col-12 no-padding">
                        <br />
                        <strong>Select Measurement Unit</strong>
                        <p>The unit will apply to all measurements</p>
                        <select onClick={(e) => this.setState({ measurementunit: e.target.value })}>
                            {units.map(unitdata => {
                                return (measurementunit === unitdata) ? <option selected value={unitdata}>{unitdata}</option> : <option value={unitdata}>{unitdata}</option>
                            })}
                        </select>
                    </div>
                    <br />
                    <input type="number"
                        value={this.state.mvalue}
                        placeholder={"Enter " + this.state.measurementDisplay.name + " value here"} onChange={(e) => {
                            var measurement = this.state.measurementDisplay;
                            measurement.mvalue = e.target.value;
                            var ms = this.state.measurements;
                            this.setState({ mvalue: e.target.value });
                            for (var m in ms) {
                                if (ms[m] === measurement.id) {
                                    ms[m] = measurement;
                                }
                            }

                            this.setState({ measurements: ms });
                        }} style={{ width: '100%', height: 42, border: '1px solid #ccc' }} />
                </div>
            </div>);
        }

        for (var f in flows) {
            const data_ = flows[f];
            customization.push(<div className="" style={{ display: (active === data_.id) ? "block" : "none" }}>
                <div id={"slider_" + data_.id} className="flexslider biggerImages" style={{ height: 330, overflow: 'hidden' }}>
                    <ul className="slides">
                        {(data_.images.map((img) => {
                            return <li style={{ position: 'relative' }}>
                                <a onClick={() => this.selectFlow(data_.id, img)}>
                                    <img src={img.image} />
                                </a>
                                {(this.state.img_list.has(img.id) === true) ? <div className="selectBtn" style={{ position: 'absolute', top: 10, right: 0, background: 'rgba(255,0,0,0.8)', color: '#fff', fontSize: 14, padding: '5px 20px', fontWeight: 'bold' }}>SELECTED</div> :
                                    <a className="selectBtn" onClick={() => this.selectFlow(data_.id, img)} style={{ cursor: 'pointer', position: 'absolute', top: 10, right: 0, background: 'rgba(0,0,0,0.8)', color: '#fff', fontSize: 14, padding: '5px 20px', fontWeight: 'bold' }}>Click to select</a>}
                            </li>
                        }))}
                    </ul>
                </div>
                <div id={"carousel_" + data_.id} className="flexslider">
                    <ul className="slides">
                        {(data_.images.map((img) => {
                            return <li className="align-middle text-center" style={{ width: 'auto !important', height: 150, overflow: 'hidden', borderRadius: 5 }}>
                                <img src={img.image} style={{}} />
                                <strong>{img.name}</strong>
                            </li>
                        }))}
                    </ul>
                </div>
            </div>);
        }

        let fabricSelect = [];
        if (fabric.id !== "") {
            fabricSelect = <div className="col-12" style={{ borderRadius: 10, }}>
                <div style={{ height: 80, borderRadius: 5, overflow: 'hidden' }}>
                    <img src={fabric.image} style={{ width: '100%' }} />
                </div>
                <center><strong>{fabric.name}</strong></center>
            </div>;
        }

        let flowList = [];
        for (var f in flow) {
            const index = f;
            flowList.push(<div key={`flowkey${index}`} className='col-auto text-center' style={{ margin: 10, borderRadius: 10, overflow: 'hidden', border: '1px solid #ccc', padding: 10 }}>
                <img src={flow[f].image} style={{ height: 100, margin: '0px 10px' }} /><br />
                <a style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => this.removeFlow(index)}>Remove <i className="fa fa-trash"></i></a>
            </div>);
        }

        var eDelivery = "";
        if (this.state.product.express_delivery !== undefined) {
            eDelivery = Number(currency.rate) * Number(this.state.product.express_delivery);
            eDelivery = eDelivery.toFixed(2);
            eDelivery = currency.name + " " + eDelivery;
        }

        var rDelivery = "";
        if (this.state.product.regular_delivery !== undefined) {
            rDelivery = Number(currency.rate) * Number(this.state.product.regular_delivery);
            rDelivery = rDelivery.toFixed(2);
            rDelivery = currency.name + " " + rDelivery;
        }

        let sizesList = [];
        if (this.state.product !== undefined) {
            if (this.state.product.sizes !== undefined) {
                if (this.state.product.sizes !== "null" && this.state.product.sizes !== null && this.state.product.sizes !== undefined) {
                    let sizesL = this.state.product.sizes;
                    sizesL = sizesL.split("|");

                    for (var s in sizesL) {
                        const data = sizesL[s];
                        sizesList.push(<div className="col-3 no-padding">
                            <div style={{
                                border: '1px solid #ccc', width: '96%', margin: 5, padding: 5,
                                background: (this.state.customermeasurements === data) ? "#222" : "#fff"
                            }}>
                                <center>
                                    <a style={{ cursor: 'pointer', cursor: 'pointer' }} onClick={() => this.setState({ custom_measurement: false, customermeasurements: data, take_measurements: false })}>
                                        {(this.state.customermeasurements === data) ?
                                            <strong style={{ color: (this.state.customermeasurements === data) ? "#fff" : "#000" }}>{data}</strong> :
                                            <p style={{ color: (this.state.customermeasurements === data) ? "#fff" : "#000" }}>{data}</p>}
                                    </a></center>
                            </div>
                        </div>);
                    }

                    if (measurements.length > 0) {
                        sizesList.push(<div className="col-4 no-padding">
                            <div style={{
                                border: '1px solid #ccc', width: '96%', margin: 5, padding: 5,
                                background: (this.state.custom_measurement === true) ? "#222" : "#fff"
                            }}>

                                <center>
                                    <a style={{ cursor: 'pointer' }} onClick={() => this.setState({ custom_measurement: true, customermeasurements: "" })}>
                                        {(this.state.custom_measurement === true) ?
                                            <strong style={{ color: (this.state.custom_measurement === true) ? "#fff" : "#000" }}>Custom Size</strong> :
                                            <p style={{ color: (this.state.custom_measurement === false) ? "#000" : "#fff" }}>Custom Size</p>}
                                    </a>
                                </center>
                            </div>
                        </div>);
                    }
                }
            }
        }

        // console.log("img_list:::", this.state.img_list);
        let deliveryOptions=[];
        if(this.state.product.regular_delivery!=undefined)
        deliveryOptions.push({value:this.state.product.regular_delivery,label:"Regular"});
        if(this.state.product.express_delivery!=undefined)
        deliveryOptions.push({value:this.state.product.express_delivery,label:"Express"});

        return (
            <>
                <div className="header_sticky header-style-1 has-menu-extra">
                    <div className="boxed">
                        <Header />
                        {loading == true && <center><Spinner fill="#222" bg="#fff" /></center>}

                        <div className="page-title parallax parallax1">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="page-title-heading">

                                            {(product.name !== undefined) && <h1 className="title">{product.name}</h1>}
                                        </div>
                                        <div className="breadcrumbs">
                                            {(this.state.product.id !== undefined) &&
                                                <ul>
                                                    <li><a href={`/catalog?menu=${this.state.product.menu}`}>{this.state.product.menu_name}</a></li>
                                                    <li><a href={`/catalog?category=${this.state.product.category}`}>{this.state.product.category_name}</a></li>
                                                    <li><a href={`/catalog?subcategory=${this.state.product.subcategory}`}>{this.state.product.subcategory_name}</a></li>
                                                </ul>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className="flat-row main-shop shop-detail">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8">

                                        <h3>PRODUCT CUSTOMIZATIONS</h3>
                                        <small><b>CLICK ON THE TEXT AND RELEVANT IMAGES BELOW TO SELECT YOUR PRODUCT CUSTOMIZATIONS.</b></small>
                                        <hr />
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="product-quantity">
                                                    {(fabrics.length > 0) &&
                                                        <><div className="add-to-cart ">
                                                            <div className="customization" style={{ padding: '10px 0px',
                                                            background: (this.state.selectedtab==="fabrics")?'#222':'#fdfdfd',
                                                        
                                                        }}
                                                        onClick={() => this.setState({ active: 0,selectedtab: "fabrics"}, () => this.reRender(0))}>
                                                                <a style={{ cursor: 'pointer',color: (this.state.selectedtab==="fabrics")?'#fff':'#222' }}>FABRICS</a>
                                                            </div>
                                                        </div><hr /></>}
                                                    {(flows.length > 0) &&
                                                        <><div className="add-to-cart">
                                                            <a style={{ cursor: 'pointer' }}>FLOWS</a>
                                                            <ul className="flows" style={{ listStyle: "none", marginLeft: 0 }}>
                                                                {flows.map(data => {
                                                                    return <li style={{ background: (this.state.selectedtab===data.name)?'#222':'#fdfdfd', padding: '10px 20px' }}
                                                                        onClick={() => this.setState({ active: data.id, take_measurements: false,selectedtab: data.name}, () => this.reRender(data.id))}>
                                                                        <a style={{color: (this.state.selectedtab===data.name)?'#fff':'#222'}}>{data.name.toUpperCase()}</a>
                                                                    </li>;
                                                                })}
                                                            </ul>
                                                        </div><hr /></>}


                                                    {(measurements.length > 0 && custom_measurement === true) &&
                                                        <><div className="add-to-cart">
                                                            <a style={{ cursor: 'pointer' }}>MEASUREMENTS</a>
                                                            <ul className="flows" style={{ listStyle: "none", marginLeft: 0 }}>
                                                                {measurements.map(data => {
                                                                    console.log("__", data);
                                                                    var mv = (data.mvalue !== undefined) ? data.mvalue : "";
                                                                    return <li style={{ background: (this.state.selectedtab===data.name)?'#222':'#fdfdfd', padding: '10px 20px' }}
                                                                        onClick={() => this.setState({ active: 99999, measurementDisplay: data, mvalue: mv, take_measurements: true ,selectedtab: data.name})}>
                                                                        <a style={{color: (this.state.selectedtab===data.name)?'#fff':'#222'}}>{data.name.toUpperCase()}</a>
                                                                    </li>;
                                                                })}
                                                            </ul>
                                                        </div><hr />
                                                        </>
                                                    }

                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                {(measurements.length === 0 && fabrics.length === 0 && flows.length === 0) &&
                                                    <p className="add-to-cart">
                                                        NO CUSTOMIZATIONS AVAILABLE FOR THIS PRODUCT
                                                    </p>
                                                }
                                                {customization}


                                                {(this.state.measurements.length > 0 && this.state.custom_measurement === true && take_measurements === true) &&
                                                    <div className='row' style={{ marginTop: 30 }}>
                                                        <div className="col-12">
                                                            <h3>CUSTOM MEASUREMENT INFO</h3>
                                                        </div>
                                                        <div className="col-12 no-padding"><hr /></div>
                                                        {this.state.measurements.map(data => {
                                                            return <div className="col-6 no-padding">
                                                                <div style={{ border: '1px solid #ccc', width: '96%', margin: 5, padding: 5, fontSize: 13 }}>
                                                                    {data.name} : {(data.mvalue) ? data.mvalue : 0}
                                                                </div>
                                                            </div>;
                                                        })}
                                                        <div className="col-12">
                                                            {savemeasurement_error !== "" && <small style={{ color: '#a00' }}>{savemeasurement_error}</small>}
                                                        </div>
                                                        <div className="col-md-6 col-sm-10 col-xs-10 no-padding product-detail">
                                                            <div className="add-to-cart" style={{}}>
                                                                <a onClick={() => this.saveMeasurements()} style={{ cursor: 'pointer' }}>SAVE MEASUREMENT</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="product-detail">
                                            <div className="inner">
                                                {(product.name !== undefined) && <h3>{product.name}</h3>}
                                                <br />
                                                {pdtImage}
                                                <br />
                                                <br />
                                                <h3>CUSTOMIZATION SELECTIONS</h3>
                                                <hr />
                                                <div className='' style={{ marginTop: 10 }}>
                                                    {fabricSelect}
                                                </div>
                                                <div className='row' style={{ marginTop: 10 }}>
                                                    {flowList}
                                                </div>

                                                {(this.state.product.sizes !== "" && sizesList.length > 0) &&
                                                    <div className='row' style={{ marginTop: 10 }}>

                                                        <div className="col-12">
                                                            <h3>STANDARD SIZE</h3>
                                                        </div>
                                                        <hr />
                                                        {sizesList}
                                                    </div>}

                                                <div className='row' style={{ marginTop: 10 }}>
                                                    {(addedtxt !== "") && <strong>{addedtxt}</strong>}
                                                    <div className="add-to-cart" style={{}}>
                                                        <a onClick={() => this.setState({ preview: true })} style={{ cursor: 'pointer', background: '#222' }}>ORDER PREVIEW</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="flat-row mail-chimp">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="text">
                                            <h3>Sign up for Send Newsletter</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="subscribe clearfix">
                                            <form action="#" method="post" acceptCharset="utf-8" id="subscribe-form">
                                                <div className="subscribe-content">
                                                    <div className="input">
                                                        <input type="email" name="subscribe-email" placeholder="Your Email" />
                                                    </div>
                                                    <div className="button">
                                                        <button type="button">SUBCRIBE</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <Modal show={preview} onHide={() => this.setState({ preview: false })} size="lg">
                            <Modal.Header closeButton>
                                <Modal.Title>Order Preview</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row">
                                    <div className='col-12'>
                                        {(this.state.errorMsg !== "") && <strong>{this.state.errorMsg}</strong>}
                                        <hr />
                                    </div>
                                    {(addedtxt !== "") && <div className='col-12'>{addedtxt}</div>}
                                    <div className='col-md-6 col-sm-12'>
                                        {(product.name !== undefined) && <h4>{product.name}</h4>}
                                        <br />
                                        {pdtImage}
                                    </div>
                                    <div className='col-md-6 col-sm-12'>
                                        <div className="product-detail">
                                            <div className="inner">
                                                <h4>CUSTOMIZATION SELECTIONS</h4>
                                                <hr />
                                                <div className='' style={{ marginTop: 10 }}>
                                                    {fabricSelect}
                                                </div>
                                                <div className='row' style={{ marginTop: 10 }}>
                                                    {flowList}
                                                </div>

                                                {(this.state.product.sizes !== "" && sizesList.length > 0) &&
                                                    <div className='row' style={{ marginTop: 10 }}>

                                                        <div className="col-12">
                                                            <h3>Standard Size</h3>
                                                        </div>
                                                        <hr />
                                                        {sizesList}
                                                    </div>
                                                }

                                                {this.state.measurements.length > 0 &&
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <h4>Measurement Details</h4>
                                                        </div>
                                                        {this.state.measurements.map(data => {
                                                            return <div className="col-6 no-padding">
                                                                <div style={{ border: '1px solid #ccc', width: '96%', margin: 5, padding: 5, fontSize: 13 }}>
                                                                    {data.name} : {(data.mvalue) ? data.mvalue : 0}
                                                                </div>
                                                            </div>;
                                                        })}
                                                    </div>}

                                                <div className='row' style={{ marginTop: 10 }}>
                                                    <div className="col-12">
                                                        <h3>Shipping Option</h3>
                                                    </div>
                                                    <hr />
                                                    <div className="col-12 no-padding">
                                                        <div className="row">
                                                        {deliveryOptions.map((del,index)=>{
                                                            const dev=del.label;
                                                            return <div className="col-5" key={index} style={{
                                                                border:'1px solid #ccc',
                                                                marginRight:10,
                                                                background:(del.label === this.state.deliveryoption)?"#222":"#fff",
                                                            }}
                                                            onClick={() => this.setState({ delivery: del.value,deliveryoption: del.label})}>
                                                                <a style={{ textAlign:'center',
                                                                color:(del.label === this.state.deliveryoption)?"#fff":"#222",
                                                                cursor: 'pointer', cursor: 'pointer' }}>
                                                                {del.label} Delivery <br/><strong>{`${this.state.currency.name} ${del.value}`}</strong>
                                                            </a></div>;
                                                        })}
                                                        </div>
                                                        <small style={{ color: '#A00' }}>{this.state.errorMsg}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="product-detail">
                                    <div className="inner">
                                        <div className='row' style={{ marginTop: 10 }}>
                                            <div className="add-to-cart" style={{}}>
                                                <a onClick={() => this.checkout(true)} style={{ cursor: 'pointer', background: '#222' }}>ADD TO CART</a>
                                            </div>
                                            <br /><br />

                                            <div className="add-to-cart">
                                                <a onClick={() => this.checkout(false)} style={{ cursor: 'pointer' }}>PROCEED TO CHECKOUT</a>
                                            </div>

                                            <div className="add-to-cart mr-10">
                                                <a onClick={() => this.setState({ preview: false })} style={{ cursor: 'pointer', border: '1px solid #000', color: '#222', background: '#fff', marginRight: 20 }}>CANCEL</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Footer>
                        </Modal>

                        <Footer />
                    </div>
                </div>
            </>
        );
    }
}
import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import queryString, { stringify } from 'query-string';
import { GetData,PatchData,PostData } from '../common';
import history from '../history';
import Spinner from '../components/Spinner';
import moment from 'moment';

export default class Orders extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            orders: [],
            statuses: ["Pending", "Paid", "Payment Failed", "Delivered", "Cancelled"],
            statusescolor: ["#B00", "#006778", "#357C3C", "#93329E", "#EF6D6D"]
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        this.setState({ loading: true });
        var user = localStorage.getItem("customer");
        user = JSON.parse(user);
        var contentSlides = await GetData(`orders/?user_id=${user.id}`);
        // console.log(contentSlides);
        if (contentSlides.code == 200) {
            this.setState({ orders: contentSlides.message.results });
        }
        this.setState({ loading: false });
    }

    repay = async (order) => {
        var user = localStorage.getItem("customer");
        user = JSON.parse(user);
        var user_profile = await GetData(`users/${user.id}/`);
        user_profile = user_profile.message;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer sk_test_08542fe254be78869d86ac0396aab30e4de7172d");

        var amount_total = Number(this.state.total) * 1;
        var formdata = JSON.stringify({
            "email": user_profile.email,
            "amount": 100,
            currency: "GHS"
        })

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        var self = this;
        await fetch("https://api.paystack.co/transaction/initialize", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status == true) {
                    self.saveOrder(result,order);
                }
            })
            .catch(error => console.log('error', error));
    }

    saveOrder=async(payment,order)=>{
        var user = localStorage.getItem("customer");
        user = JSON.parse(user);

        var payload = new URLSearchParams();
        payload.append("payment_id", payment.data.reference);
        var user = await PatchData(`orders/${order.id}/`, payload, false);

        // console.log(user);
        if (user.code == 201) {
            if(this.state.payment==3)
                window.location=payment.data.authorization_url;
        } else {
            var errors = "";
            for (var e in user.message) {
                const data = user.message[e];
                for (var d in data) {
                    errors += `${data[d]}`;
                }
            }
            this.setState({ errormsg: errors });
        }
    }

    render() {
        const { orders } = this.state;


        return (
            <>
                <div className="header_sticky header-style-1 has-menu-extra">
                    <div className="boxed">
                        <Header />
                        <div className="page-title parallax parallax1">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="page-title-heading">
                                            <h1 className="title">My Orders</h1>
                                        </div>
                                        <div className="breadcrumbs">
                                            <ul>
                                                <li><a href="/">Home</a></li>
                                                <li><a href="/blogs">Blog</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className="blog-posts style2">
                            <div className="container">
                                {(this.state.loading == true) &&
                                    <div className="text-center"><Spinner bg="#fff" fill="#222" /></div>}
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="post-wrap style2">
                                            {(orders.map((data, index) => {
                                                // var img = "";
                                                // if (data.product.images != undefined) {
                                                //     if (data.product.images[0] != undefined) {
                                                //         img = data.product.images[0].image;
                                                //     }
                                                // }
                                                // var price = Number(data.quantity) * Number(data.product.price);
                                                return <article className="post clearfix">
                                                    <div className="featured-post">
                                                        {/* <img src={img} alt="image" width="200" /> */}
                                                    </div>
                                                    <div className="content-post">
                                                        <div className="row">
                                                            <div className="col-8">
                                                                <div className="title-post">
                                                                    <h2><small>Order ID: </small><a href={`/orderdetails?id=${data.id}`}>{data.transaction_id}</a></h2>
                                                                </div>
                                                                <ul className="meta-post">
                                                                    <li className="author">
                                                                        <span>Date: </span><a href="#">{data.ordering_date}</a>
                                                                    </li>
                                                                </ul>
                                                                <div className="title-post">
                                                                    <strong style={{ background: this.state.statusescolor[data.order_status], padding: "10px 20px", color: '#fff' }}>{this.state.statuses[data.order_status]}</strong>
                                                                </div>
                                                            </div>
                                                            <div className="col-4 text-right">
                                                                <div className="title-post">
                                                                    <h2><small>GBP </small><a href="#">{data.total_price}</a></h2>
                                                                </div>
                                                                {(data.order_status == 0) &&
                                                                    <ul className="meta-post">
                                                                        <li className="author">
                                                                            <span>Click to retry payment</span>
                                                                        </li>
                                                                    </ul>}
                                                                {(data.order_status == 0) &&
                                                                    <div className="title-post">
                                                                        <button style={{ background: '#f63440', padding: "5px 20px", color: '#fff' }} onClick={() => this.repay(data)}>Make Payment</button>
                                                                    </div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                            }))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <Footer />
                    </div>
                </div>
            </>
        );
    }
}
import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import { GetData } from '../common';
import Parser from 'html-react-parser';
import queryString from 'query-string';
import moment from 'moment';

export default class About extends Component {
    constructor(props) {
        super(props)
        this.state = {
            content: { content: "" },
            dataSet: [],
            id: ""
        }
    }

    componentDidMount() {
        this.laodData();
    }

    laodData = async () => {
        var id = queryString.parse(this.props.location.search).id;
        this.setState({ id: id });
        var contentSlides = await GetData(`blogs/${id}/`);
        if (contentSlides.code == 200) {
            this.setState({ content: contentSlides.message });
        }

        contentSlides = await GetData("blogs/?status=true");
        if (contentSlides.code == 200) {
            this.setState({ dataSet: contentSlides.message.results });
        }
    }

    render() {
        const { content, dataSet, id } = this.state;

        let moreblogs = dataSet.filter(function (data) {
            return data.id != id
        });
        let video_image = [];
        if (content != undefined) {
            if (content.image != undefined) {
                video_image.push((content.image.endsWith(".png") || content.image.endsWith(".jpg") || content.image.endsWith(".jpeg") || content.image.endsWith(".gif")) ?
                    <img src={content.image} alt="image" style={{ width: '100%' }} /> :
                    <video controls width={"100%"} height="450">
                        <source src={content.image} type="video/mp4"></source>
                    </video>
                );
            }
        }

        return (
            <>
                <div className="header_sticky header-style-1 has-menu-extra">
                    <div className="boxed">
                        <Header />
                        <section className="blog-posts blog-detail">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-9">
                                        <div className="post-wrap detail">

                                            <article className="post clearfix">
                                                <div className="title-post">
                                                    <h2><a href="#">{content.title}</a></h2>
                                                </div>
                                                <div className="content-post">
                                                    {/* <ul className="meta-post">
                                                        <li className="author padding-left-2">
                                                            <span>Posted By: </span><a href="#">Admin</a>
                                                        </li>
                                                        <li className="comment">
                                                            <a href="#">10 Comment</a>
                                                        </li>
                                                        <li className="date">
                                                            <span>Date : {moment(content.date).format("Do MMM,YYYY")} </span>
                                                        </li>
                                                    </ul> */}
                                                    <div className="featured-post">
                                                        {video_image}
                                                    </div>
                                                    <div className="entry-post">
                                                        {Parser(content.content)}
                                                    </div>
                                                </div>
                                                <div className="direction">
                                                    <ul className="tags-share clearfix">
                                                        <li className="float-left">
                                                            <div className="tags">
                                                                <span>Read More:</span><a href={content.url}>{content.url}</a>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </article>

                                        </div>

                                    </div>
                                    <div className="col-md-3">
                                        <div className="sidebar">

                                            <div className="widget widget-news-latest">
                                                <h5 className="widget-title">Related Blogs</h5>
                                                <ul className="popular-news clearfix">
                                                    {moreblogs.map(data => {
                                                        return <li>
                                                            <div style={{ height: 200, overflow: 'hidden' }}>
                                                                <a href={'/blogsdetails?id=' + data.id}>
                                                                    {(data.image.endsWith(".png") || data.image.endsWith(".jpg") || data.image.endsWith(".jpeg") || data.image.endsWith(".gif")) ?
                                                                        <img src={data.image} alt="image" /> :
                                                                        <video controls width={"100%"} height="200">
                                                                            <source src={data.image} type="video/mp4"></source>
                                                                        </video>}
                                                                </a>
                                                            </div>
                                                            <h4>
                                                                <a href={`/blogsdetails?id=${data.id}`}>{data.title}</a>
                                                            </h4>
                                                        </li>;
                                                    })}
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <Footer />
                    </div>
                </div>
            </>
        );
    }
}
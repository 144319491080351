import React, { Component } from 'react';
import history from '../history';

export default class Logout extends Component{
    componentDidMount(){
        localStorage.removeItem("tokenDetails");
        localStorage.removeItem("customer");
        localStorage.removeItem("loginNav");
        localStorage.removeItem("cart");

        history.push("/login");
        window.location = window.location.href;
    }
    render(){
        return (<></>);
    } 
}